import { useState } from 'react';

import {
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';

import { useAppSelector } from 'store';
import {
    useDeleteExpertiseMutation,
    useDeleteResourceMutation,
} from 'store/features';

interface EditsProps {
    type: 'expertise' | 'resource';
    linkText: 'expertise' | 'inhalt';
    id: number;
    onOpenModal?: () => void;
}

export const Edits = ({ type, linkText, id, onOpenModal }: EditsProps) => {
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    return (
        <Space size="middle">
            <>
                <Button onClick={onOpenModal} type="primary">
                    <EditOutlined /> Editieren
                </Button>
                {showAdminView && (
                    <DeleteButton type={type} id={id} titleText={linkText} />
                )}
            </>
        </Space>
    );
};

interface IDeleteButtonProps {
    type: 'expertise' | 'resource';
    titleText: 'expertise' | 'inhalt';
    id: number;
}

const DeleteButton = ({ type, titleText, id }: IDeleteButtonProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState<string | undefined>();
    const [deleteExpertise] = useDeleteExpertiseMutation();
    const [deleteResource] = useDeleteResourceMutation();

    const setTitle = () => {
        if (titleText === 'inhalt') {
            return 'Inhalt';
        } else {
            return 'Expertise';
        }
    };
    const title = setTitle();

    const handleOk = () => {
        setIsLoading(true);

        switch (type) {
            case 'resource':
                deleteResource(id).catch((error) => console.error(error));
                break;
            case 'expertise':
                deleteExpertise(id).catch((error) => console.error(error));
                break;
            default:
                console.error('Not deleting anything, unknown deletion type!');
        }
    };

    const showModal = () => {
        switch (type) {
            case 'resource':
                setContent(
                    'Das Löschen eines Inhaltes löscht auch alle verknüpften Expertisen.'
                );
                break;
            case 'expertise':
                setContent('Die Expertise wird endgültig gelöscht.');
                break;
        }

        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button type="default" onClick={showModal}>
                <DeleteOutlined /> Löschen
            </Button>
            <Modal
                title={`${title} wirklich löschen?`}
                open={isModalVisible}
                okText={<span>{isLoading && <LoadingOutlined />} Löschen</span>}
                onOk={handleOk}
                okButtonProps={{ type: 'primary', danger: true }}
                cancelText="Abbrechen"
                onCancel={handleCancel}
            >
                {content}
            </Modal>
        </>
    );
};
