import { ChangeEvent, RefObject, useCallback } from 'react';

import { Input, InputRef } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';

interface ITextFilterDropdownProps extends FilterDropdownProps {
    placeholder: string;
    ref: RefObject<InputRef>;
}

const TextFilterDropdown = ({
    confirm,
    placeholder,
    ref,
    setSelectedKeys,
}: ITextFilterDropdownProps) => {
    const setFilter = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const input = event.target.value;
            if (input.length === 0) {
                setSelectedKeys([]);
            } else {
                setSelectedKeys([input]);
            }

            confirm({ closeDropdown: false });
        },
        [setSelectedKeys]
    );

    return (
        <div style={{ padding: 8 }}>
            <Input
                allowClear
                onChange={setFilter}
                placeholder={placeholder}
                ref={ref}
            />
        </div>
    );
};

export const TextFilterDropdownInstance = (
    props: FilterDropdownProps,
    placeholder: string,
    inputRef: React.RefObject<InputRef>
): React.JSX.Element => (
    <TextFilterDropdown
        placeholder={placeholder}
        ref={inputRef}
        prefixCls={props.prefixCls}
        setSelectedKeys={props.setSelectedKeys}
        selectedKeys={props.selectedKeys}
        confirm={props.confirm}
        close={props.close}
        visible={props.visible}
    />
);
