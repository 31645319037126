import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Classes, Tag } from 'models';
import { CustomTags } from 'modules/_common/components';
import { useGetResourceByIdQuery } from 'store/features';

import * as resourceDetail from './resourceDetail.less';

const classes = resourceDetail as Classes;
document.body.className = classes.body;

interface ResourceDetailsProps {
    resourceId: number;
    onDrawerClose: () => void;
}

export const ResourceDetail = ({
    resourceId,
    onDrawerClose,
}: ResourceDetailsProps) => {
    const { data: resource, isLoading: isLoadingResource } =
        useGetResourceByIdQuery(resourceId);

    return (
        <>
            {isLoadingResource ? (
                <div>
                    <LoadingOutlined />
                </div>
            ) : (
                <div className="resource-detail-content">
                    {resource === undefined && <div>Kein Inhalt gefunden.</div>}
                    <h1>{resource?.definition}</h1>
                    <div className="resource-detail-row">
                        <span className="resource-detail-label">
                            Bezeichnung:
                        </span>
                        {resource?.definition}
                    </div>
                    <div className="resource-detail-row">
                        <span className="resource-detail-label">Anbieter:</span>
                        {resource?.source}
                    </div>
                    <div className="resource-detail-row  flex">
                        <span className="resource-detail-label">
                            Beschreibung:
                        </span>
                        <pre className="description-format">
                            <span className="format-text">
                                {resource?.description}
                            </span>
                        </pre>
                    </div>
                    <div className="resource-detail-row">
                        <span className="resource-detail-label">Tags:</span>
                        {resource?.tags.map((tag: Tag) => {
                            return (
                                <CustomTags
                                    label={tag.definition}
                                    key={tag.id}
                                    closable={false}
                                />
                            );
                        })}
                    </div>
                    <div className="resource-detail-row padding-bottom-2">
                        <span className="resource-detail-label">
                            Gültigkeitsdauer:
                        </span>
                        {resource?.validity} Monate
                    </div>
                    <Button type="primary" onClick={onDrawerClose}>
                        Schließen
                    </Button>
                </div>
            )}
        </>
    );
};
