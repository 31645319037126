export const linkValueSplitter = (value: string, key: number) => {
    const HttpRegex = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\\+.~#?&/=]*)/
    );
    return value.split(' ').map((splittedValue: string) => {
        if (splittedValue.match(HttpRegex)) {
            return (
                <a
                    key={key.toString() + '-a'}
                    style={{ marginRight: 5 }}
                    target="_blank"
                    rel="noreferrer"
                    href={splittedValue}
                >
                    {splittedValue}
                </a>
            );
        } else {
            return splittedValue + ' ';
        }
    });
};
