import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { Employee } from 'models';
import { getAccessToken } from 'modules/_common/utils';

const { API_URL } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: `${String(API_URL)}/api/`,
    prepareHeaders: async (headers) => {
        const token = await getAccessToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const employeeApi = createApi({
    reducerPath: 'fetchEmployees',
    baseQuery: baseQuery,
    tagTypes: ['Employees', 'Employee'],
    endpoints: (builder) => ({
        getAllEmployees: builder.query<Employee[], void>({
            query: () => 'employees',
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: 'Employees', id } as const)
                          ),
                          { type: 'Employees', id: 'LIST' },
                      ]
                    : [{ type: 'Employees', id: 'LIST' }],
        }),
        getCurrentEmployee: builder.query<Employee, void>({
            query: () => 'employees/current',
            providesTags: ['Employee'],
        }),
    }),
});

export const { useGetAllEmployeesQuery, useGetCurrentEmployeeQuery } =
    employeeApi;
