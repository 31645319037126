import { Alert } from 'antd';

interface EmptyCertificateStateProps {
    isAdmin: boolean;
}

export const EmptyCertificateState = ({
    isAdmin,
}: EmptyCertificateStateProps) => {
    return (
        <Alert
            type={'info'}
            message={'Information'}
            description={
                isAdmin
                    ? 'Es gibt keine ablaufenden Zertifikate in den nächsten 365 Tagen.'
                    : 'Du hast keine ablaufenden Zertifikate.'
            }
            showIcon={true}
        />
    );
};
