import { Button, Form, FormInstance } from 'antd';

interface CreateResourceDrawerModalButtonsProps {
    form: FormInstance;
    onCancel?: () => void;
}

export const CreateResourceDrawerModalButtons = ({
    onCancel,
}: CreateResourceDrawerModalButtonsProps) => {
    return (
        <>
            <Form.Item className="form-buttons">
                <div className="flex" style={{ gap: 10 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        data-testid="drawer-save"
                    >
                        Hinzufügen
                    </Button>
                    <Button
                        type="default"
                        onClick={onCancel}
                        data-testid="drawer-cancel"
                    >
                        Abbrechen
                    </Button>
                </div>
            </Form.Item>
        </>
    );
};
