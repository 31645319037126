import { LogoutOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Button } from 'antd';

export const Logout = () => {
    const { instance } = useMsal();

    const logoutUser = () => {
        void instance.logout();
    };

    return (
        <div className="header-button">
            <Button
                onClick={logoutUser}
                key="logout"
                type="primary"
                danger
                style={{ background: '#B10B38' }}
            >
                <LogoutOutlined className="button-icon" />
                Logout
            </Button>
        </div>
    );
};
