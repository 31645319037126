import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import {
    ILearningtrail,
    ILearningtrailRequestData,
    ILearningtrailUpdateData,
} from 'models';
import { getAccessToken } from 'modules/_common/utils';

const { API_URL } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: `${String(API_URL)}/api/`,
    prepareHeaders: async (headers) => {
        const token = await getAccessToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const learningtrailApi = createApi({
    reducerPath: 'learningtrails',
    baseQuery: baseQuery,
    tagTypes: ['Learningtrails'],
    endpoints: (builder) => ({
        getAllLearningtrails: builder.query<ILearningtrail[], void>({
            query: () => 'learningtrails',
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) =>
                                  ({ type: 'Learningtrails', id } as const)
                          ),
                          { type: 'Learningtrails', id: 'LIST' },
                      ]
                    : [{ type: 'Learningtrails', id: 'LIST' }],
        }),
        getLearningtrailById: builder.query<ILearningtrail, number>({
            query: (learningtrailId: number) =>
                `learningtrails/${learningtrailId}`,
            providesTags: (_result, _error, id) => [
                { type: 'Learningtrails', id: id },
            ],
        }),
        addNewLearningtrail: builder.mutation({
            query: (newLearningtrail: ILearningtrailRequestData) => ({
                url: 'learningtrails',
                method: 'POST',
                body: newLearningtrail,
            }),
            invalidatesTags: [{ type: 'Learningtrails', id: 'LIST' }],
        }),
        updateLearningtrail: builder.mutation({
            query: (learningtrailToUpdate: ILearningtrailUpdateData) => ({
                url: `learningtrails/${learningtrailToUpdate.id}`,
                method: 'PUT',
                body: learningtrailToUpdate,
            }),
            invalidatesTags: (_result, _error, { id }) => [
                { type: 'Learningtrails', id: id },
                { type: 'Learningtrails', id: 'LIST' },
            ],
        }),
        deleteLearningtrail: builder.mutation({
            query: (learningtrailId: number) => ({
                url: `learningtrails/${learningtrailId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, id) => [
                { type: 'Learningtrails', id: id },
                { type: 'Learningtrails', id: 'LIST' },
            ],
        }),
    }),
});

export const {
    useGetAllLearningtrailsQuery,
    useGetLearningtrailByIdQuery,
    useAddNewLearningtrailMutation,
    useUpdateLearningtrailMutation,
    useDeleteLearningtrailMutation,
} = learningtrailApi;
