import { Tag } from 'models';

const duplicatedTag = (
    inputValue: string,
    tags: Tag[] | undefined
): boolean | undefined => {
    return tags?.some(
        (tag: Tag) => tag.definition.toUpperCase() === inputValue.toUpperCase()
    );
};

const noSpacesInInput = (inputValue: string): boolean => {
    return inputValue.toUpperCase().search(' ') == -1;
};

export const tagIsValid = (
    inputValue: string,
    tags: Tag[] | undefined
): boolean => {
    const validTag =
        inputValue.length > 1 &&
        !duplicatedTag(inputValue, tags) &&
        noSpacesInInput(inputValue) &&
        inputValue.length <= 20;

    return validTag;
};

export const validateTag = (
    inputValue: string,
    tags: Tag[] | undefined
): Promise<void> => {
    if (tagIsValid(inputValue, tags)) {
        return Promise.resolve();
    } else if (inputValue != '' && !noSpacesInInput(inputValue)) {
        return Promise.reject(
            new Error('Tag darf keine Leerzeichen enthalten!')
        );
    } else if (inputValue != '' && duplicatedTag(inputValue, tags)) {
        return Promise.reject(
            new Error('Tag mit diesem Namen gibt es bereits!')
        );
    } else if (inputValue != '' && inputValue.length <= 1) {
        return Promise.reject(
            new Error('Tag muss aus mindestens zwei Zeichen bestehen!')
        );
    }

    return Promise.resolve();
};
