import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserRoleState {
    isAdmin: boolean;
    showAdminView: boolean;
    userName: string;
}

const initialState: UserRoleState = {
    isAdmin: false,
    showAdminView: false,
    userName: '',
};

export const UserRoleSlice = createSlice({
    name: 'userRoles',
    initialState: initialState,
    reducers: {
        setAdminRole: (state, action: PayloadAction<{ isAdmin: boolean }>) => {
            return {
                ...state,
                isAdmin: action.payload.isAdmin,
            };
        },
        setShowAdminView: (state, action: PayloadAction<boolean>) => {
            return { ...state, showAdminView: action.payload };
        },
        setUserName: (state, action: PayloadAction<string>) => {
            return { ...state, userName: action.payload };
        },
    },
});

export default UserRoleSlice.reducer;
export const { setAdminRole, setShowAdminView, setUserName } =
    UserRoleSlice.actions;
