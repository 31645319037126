import { Select } from 'antd';

import { Tag } from 'models';
import { CustomTags, ITagRenderProps } from 'modules/_common/components';
import { mapTagsToOptions } from 'modules/_common/services';
import { filterOptions } from 'modules/_common/utils';

interface ICustomSelectProps {
    tags: Array<Tag>;
}

export const TagsSelect = ({ tags, ...restProps }: ICustomSelectProps) => {
    return (
        <Select
            placeholder="Wähle Tags aus"
            notFoundContent="Keine passenden Ergebnisse"
            mode="multiple"
            showArrow
            tagRender={({ label, closable, onClose }: ITagRenderProps) => {
                return (
                    <CustomTags
                        closable={closable}
                        onClose={onClose}
                        label={label}
                    />
                );
            }}
            placement="topLeft"
            style={{ width: '100%' }}
            options={mapTagsToOptions(tags)}
            filterOption={(inputValue, option) =>
                filterOptions(inputValue, option)
            }
            {...restProps}
        />
    );
};
