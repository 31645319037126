export const sortByAlphabet = (a: string | null, b: string | null): number => {
    if (a === null && b !== null) {
        return -1;
    }

    if (a !== null && b === null) {
        return 1;
    }

    if (a !== null && b !== null) {
        const uppercaseA = a.toUpperCase();
        const uppercaseB = b.toUpperCase();

        if (uppercaseA < uppercaseB) {
            return -1;
        }

        if (uppercaseA > uppercaseB) {
            return 1;
        }
    }

    return 0;
};

export const sortByDateNullsLast = (
    a: string | null,
    b: string | null
): number => {
    const dateA = new Date(a ?? '3999-12-31T23:59:59');
    const dateB = new Date(b ?? '3999-12-31T23:59:59');
    return dateA.getTime() - dateB.getTime();
};

export const sortByDate = (a: string | null, b: string | null): number => {
    const dateA = new Date(a ?? '1900-01-01T00:00:00');
    const dateB = new Date(b ?? '1900-01-01T00:00:00');
    return dateA.getTime() - dateB.getTime();
};

export const sortByBoolean = (a: boolean, b: boolean): number => {
    if (a && !b) {
        return 1;
    }

    if (!a && b) {
        return -1;
    }

    return 0;
};
