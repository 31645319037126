import { InfoCircleOutlined, LineOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { Resource } from 'models';

interface LearningtrailResourceIconProps {
    onShowDrawer: () => void;
    resource: Resource;
    lastItem: boolean;
}

export const LearningtrailResourceIcon = ({
    onShowDrawer,
    resource,
    lastItem,
}: LearningtrailResourceIconProps) => {
    return (
        <>
            <Tooltip title={resource.definition}>
                <InfoCircleOutlined
                    onClick={onShowDrawer}
                    style={{
                        color: '#b8adae',
                    }}
                    data-testid={'resource-icon'}
                />
            </Tooltip>
            {!lastItem && (
                <LineOutlined
                    style={{
                        color: '#b8adae',
                    }}
                />
            )}
        </>
    );
};
