import { useEffect, useState } from 'react';

import {
    BulbOutlined,
    ControlOutlined,
    HomeOutlined,
    LeftOutlined,
    RightOutlined,
    RocketOutlined,
    TeamOutlined,
    TrophyOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { Classes } from 'models';
import { useAppSelector } from 'store';

import * as sideMenu from './sideMenu.less';
import logo from '../../../../assets/images/itfactum-logo.png';

const classes = sideMenu as Classes;
document.body.className = classes.body;

const initialMenuItems: MenuProps['items'] = [
    {
        label: <Link to="/">Dashboard</Link>,
        key: '/',
        icon: <HomeOutlined />,
    },
    {
        label: <Link to="/zertifikate">Zertifikate</Link>,
        key: '/zertifikate',
        icon: <TrophyOutlined />,
    },
    {
        label: <Link to="/expertisen">Expertisen</Link>,
        key: '/expertisen',
        icon: <BulbOutlined />,
    },
    {
        label: <Link to="/inhalte">Inhalte</Link>,
        key: '/inhalte',
        icon: <RocketOutlined />,
    },
    {
        label: <Link to="/lernpfade">Lernpfade</Link>,
        key: '/lernpfade',
        icon: <ControlOutlined />,
    },
];

export const SideMenu = () => {
    const [collapsed, setCollapsed] = useState(false);
    const routeLocation = useLocation();
    const [currentSiteKey, setCurrentKey] = useState(routeLocation.pathname);
    const [menuItems, setMenuItems] = useState(initialMenuItems);

    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );

    const adminMenuItems: MenuProps['items'] = [
        {
            label: <Link to="/mitarbeiter">Mitarbeiter</Link>,
            key: '/mitarbeiter',
            icon: <TeamOutlined />,
        },
    ];

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const navigateTo: MenuProps['onClick'] = (site) => {
        setCurrentKey(site.key);
    };

    useEffect(() => {
        if (showAdminView) {
            const newMenuItems = [...initialMenuItems, ...adminMenuItems];
            setMenuItems(newMenuItems);
        } else {
            const userMenuItems = menuItems.filter(
                (element) =>
                    !adminMenuItems.some((el) => el?.key === element?.key)
            );
            setMenuItems(userMenuItems);
        }
    }, [showAdminView]);

    return (
        <>
            <div
                onClick={() => {
                    setCurrentKey('/');
                }}
            >
                <Link to="/">
                    <img src={logo} alt="Firmenlogo" className="logo" />
                </Link>
            </div>
            <Menu
                style={{
                    paddingTop: 10,
                    fontSize: '1rem',
                    borderRight: '1px solid #f3f3f3',
                    boxShadow: '4px -2px 2px -2px #f3f3f3',
                    width: collapsed ? 50 : 160,
                }}
                mode="inline"
                theme="light"
                selectedKeys={[currentSiteKey]}
                inlineCollapsed={collapsed}
                items={menuItems}
                onClick={navigateTo}
                data-testid="side-menu"
            />

            <div
                className="side-menu-drawer-handle"
                data-testid="menu-drawer"
                onClick={toggleCollapsed}
            >
                {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </div>
        </>
    );
};
