import { Avatar, Card } from 'antd';

import { useAppSelector } from 'store';

export const WelcomeCard = () => {
    const userName = useAppSelector((state) => state.userRoles.userName);

    return (
        <Card>
            <div className="flex">
                <div
                    className="flex"
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        alt="useravatar"
                        size={60}
                        style={{ marginRight: 15 }}
                    >
                        {userName?.charAt(0)}
                    </Avatar>
                    <div style={{ lineHeight: '1.25rem' }}>
                        Herzlich willkommen,
                        <h2 style={{ margin: 0 }}>{userName}</h2>
                    </div>
                </div>
            </div>
        </Card>
    );
};
