import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { Expertise, ExpertiseCreateBody, ExpertiseUpdateBody } from 'models';
import { getAccessToken } from 'modules/_common/utils';

const { API_URL } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: `${String(API_URL)}/api/`,
    prepareHeaders: async (headers) => {
        const token = await getAccessToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const expertiseApi = createApi({
    reducerPath: 'fetchExpertises',
    baseQuery: baseQuery,
    tagTypes: ['Expertises'],
    endpoints: (builder) => ({
        getAllExpertises: builder.query<Expertise[], boolean>({
            query: (onlyExpiring: boolean) => {
                if (onlyExpiring) {
                    return `expertises/?onlyExpiring=${onlyExpiring.toString()}`;
                }
                return 'expertises';
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: 'Expertises', id } as const)
                          ),
                          { type: 'Expertises', id: 'LIST' },
                      ]
                    : [{ type: 'Expertises', id: 'LIST' }],
        }),
        getExpertiseById: builder.query<Expertise, number>({
            query: (expertiseId: number) => `expertises/${expertiseId}`,
            providesTags: (_result, _error, id) => [
                { type: 'Expertises', id: id },
            ],
        }),
        addNewExpertise: builder.mutation({
            query: (newExpertise: ExpertiseCreateBody) => ({
                url: 'expertises',
                method: 'POST',
                body: newExpertise,
            }),
            invalidatesTags: [{ type: 'Expertises', id: 'LIST' }],
        }),
        updateExpertise: builder.mutation({
            query: (expertiseToUpdate: ExpertiseUpdateBody) => ({
                url: `expertises/${expertiseToUpdate.id}`,
                method: 'PUT',
                body: expertiseToUpdate,
            }),
            invalidatesTags: (_result, _error, { id }) => [
                { type: 'Expertises', id: id },
                { type: 'Expertises', id: 'LIST' },
            ],
        }),
        deleteExpertise: builder.mutation({
            query: (expertiseId: number) => ({
                url: `expertises/${expertiseId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, id) => [
                { type: 'Expertises', id: id },
                { type: 'Expertises', id: 'LIST' },
            ],
        }),
    }),
});

export const {
    useGetAllExpertisesQuery,
    useGetExpertiseByIdQuery,
    useAddNewExpertiseMutation,
    useUpdateExpertiseMutation,
    useDeleteExpertiseMutation,
} = expertiseApi;
