import { useState } from 'react';

import {
    DeleteOutlined,
    LoadingOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Tag as AntdTag, Input, Modal, Popover } from 'antd';

import { Tag } from 'models';
import { getTagColor } from 'modules/_common/utils';
import {
    useAddNewTagMutation,
    useDeleteTagMutation,
    useGetAllTagsQuery,
} from 'store/features';

export const EditableTagsGroup = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedTagId, setSelectedTagId] = useState(-1);
    const { data: tags, isLoading: isLoadingTags } = useGetAllTagsQuery();
    const [inputValue, setInputValue] = useState<string>('');
    const [inputVisible, setInputVisible] = useState<boolean>(false);
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [popoverContent, setPopoverContent] = useState<string>('');

    const [create] = useAddNewTagMutation();
    const [remove] = useDeleteTagMutation();

    const removeTag = async (id: number) => {
        await remove(id);
    };

    const createTag = async () => {
        await create({ definition: inputValue.toUpperCase() });

        setInputVisible(false);
        setInputValue('');
    };

    const closeInputIfEmpty = () => {
        if (inputValue == '') {
            setPopoverVisible(false);
            setInputVisible(false);
        }
    };

    const defineMessage = (noSpaces: boolean, duplicate?: boolean) => {
        if (!noSpaces) {
            setPopoverContent('Tag darf keine Leerzeichen enthalten');
        }
        if (inputValue.length > 20) {
            setPopoverContent('Tag darf nicht länger als 20 Zeichen sein');
        }
        if (duplicate) {
            setPopoverContent('Tag schon vorhanden');
        }
    };

    const handleIncorrectInput = () => {
        setPopoverVisible(true);
        setInputValue('');
        setInputVisible(true);
    };

    const handleInputConfirm = () => {
        const duplicate = tags?.some(
            (tag: Tag) =>
                tag.definition.toUpperCase() === inputValue.toUpperCase()
        );
        const noSpaces = inputValue.toUpperCase().search(' ') == -1;

        if (
            inputValue.length > 1 &&
            !duplicate &&
            noSpaces &&
            inputValue.length <= 20
        ) {
            createTag().catch((error) => console.error(error));
        } else {
            defineMessage(noSpaces, duplicate);
            handleIncorrectInput();
            closeInputIfEmpty();
        }
    };

    const deleteTag = (id: number, event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setShowModal(true);
        setSelectedTagId(id);
    };

    const submitDelete = () => {
        removeTag(selectedTagId).catch((error) => console.error(error));
        setShowModal(false);
        setSelectedTagId(-1);
    };

    const cancelDelete = () => {
        setShowModal(false);
        setSelectedTagId(-1);
    };

    return (
        <>
            {isLoadingTags ? (
                <LoadingOutlined />
            ) : (
                tags?.map((tag: Tag) => {
                    const colour = getTagColor(tag.definition.length);
                    return (
                        <AntdTag
                            className="edit-tag"
                            key={tag.id}
                            color={colour}
                            closable
                            onClose={(event) => deleteTag(tag.id, event)}
                            closeIcon={
                                <DeleteOutlined data-testid="close-button" />
                            }
                        >
                            <span
                                onDoubleClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                {tag.definition}
                            </span>
                        </AntdTag>
                    );
                })
            )}

            {inputVisible && (
                <>
                    <Popover
                        content={<p data-testid="popover">{popoverContent}</p>}
                        open={popoverVisible}
                        trigger={'click'}
                    >
                        <Input
                            autoFocus
                            type="text"
                            size="small"
                            className="tag-input"
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                                setPopoverVisible(false);
                            }}
                            onBlur={() => handleInputConfirm()}
                            onPressEnter={() => handleInputConfirm()}
                            maxLength={20}
                            data-testid="taginput"
                        />
                    </Popover>
                    <small
                        className={`${
                            inputValue.length === 20
                                ? 'text-danger'
                                : 'text-invisible'
                        }`}
                    >
                        max. 20 Zeichen
                    </small>
                </>
            )}
            {!isLoadingTags && !inputVisible && (
                <AntdTag
                    className="site-tag-plus"
                    data-testid="add-button"
                    onClick={() => setInputVisible(true)}
                >
                    <PlusOutlined /> Neuer Tag
                </AntdTag>
            )}
            <Modal
                open={showModal}
                onOk={submitDelete}
                onCancel={cancelDelete}
                centered={true}
                okText={'Löschen'}
                cancelText={'Abbrechen'}
            >
                <p>Möchtest du das Tag wirklich löschen?</p>
            </Modal>
        </>
    );
};
