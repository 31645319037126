import { Form } from 'antd';

interface ICustomFormItemProps {
    name?: string;
    label?: string;
    required?: boolean;
    maxLength?: number;
    children?: React.ReactNode;
    noStyle?: boolean;
}

export const CustomFormItem = ({
    name,
    label,
    noStyle = false,
    required: required,
    maxLength,
    children,
}: ICustomFormItemProps) => {
    return maxLength ? (
        <Form.Item
            name={name}
            label={label}
            noStyle={noStyle}
            rules={[
                {
                    required: required,
                },
                {
                    max: maxLength,
                    message: `Eingabe darf nicht länger als ${maxLength} Zeichen sein!`,
                },
            ]}
        >
            {children}
        </Form.Item>
    ) : (
        <Form.Item
            name={name}
            label={label}
            noStyle={noStyle}
            rules={[
                {
                    required: required,
                },
            ]}
        >
            {children}
        </Form.Item>
    );
};
