import { Form, FormInstance, Select } from 'antd';

import { ExpertiseFormValues, Resource } from 'models';
import { CustomFormItem } from 'modules/_common/components';
import DatePicker from 'modules/_common/components/DatePicker';
import { filterOptions } from 'modules/_common/utils';
import {
    ExpertiseFormEmployeeSelect,
    ExpertiseWithDateCheckFormItem,
} from 'modules/expertises/components';
import {
    mapResourcesAndSourceToOptions,
    setExpiryDate,
} from 'modules/expertises/services';
import { useAppSelector } from 'store';

import { ExpertiseFormEmployeesSelect } from './ExpertiseFormEmployeesSelect';

interface IExpertiseFormProps {
    onFinish: (values: ExpertiseFormValues) => void | undefined;
    resources: Resource[];
    form: FormInstance<ExpertiseFormValues>;
}

export const ExpertiseForm = ({
    onFinish,
    resources,
    form,
}: IExpertiseFormProps) => {
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );

    return (
        <Form
            {...{ labelCol: { span: 5 }, wrapperCol: { span: 20 } }}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={{ required: '${label} ist erforderlich!' }}
            form={form}
            style={{ width: 500 }}
            id="expertise-form"
            data-testid="expertise-form"
        >
            <CustomFormItem
                name={'resourceId'}
                label={'Inhalt'}
                required={true}
            >
                <Select
                    placeholder="Wähle einen Inhalt"
                    notFoundContent="Keine passenden Ergebnisse"
                    showArrow
                    style={{ width: '100%' }}
                    options={mapResourcesAndSourceToOptions(resources)}
                    showSearch
                    filterOption={(inputValue, option) =>
                        filterOptions(inputValue, option)
                    }
                />
            </CustomFormItem>
            {showAdminView &&
                (form.getFieldValue('employeeId') ? (
                    <ExpertiseFormEmployeeSelect />
                ) : (
                    <ExpertiseFormEmployeesSelect />
                ))}
            <CustomFormItem name={'dateBegin'} label={'Beginn'} required={true}>
                <DatePicker format={'DD.MM.YYYY'} placeholder="Datum wählen" />
            </CustomFormItem>
            <ExpertiseWithDateCheckFormItem
                dependencies={['dateBegin']}
                name={'examinationDate'}
                label={'Prüfungsdatum'}
                formValues={['dateBegin']}
                formAlert={'Das Prüfungsdatum darf nicht vor dem Beginn liegen'}
                onDatePickerChange={(dateBegin: Date | null) =>
                    setExpiryDate(dateBegin, form, resources)
                }
                onRollback={() =>
                    form.setFieldsValue({ examinationDate: null })
                }
            />
            <ExpertiseWithDateCheckFormItem
                name={'expiryDate'}
                label={'Ablaufdatum'}
                formAlert={
                    'Das Ablaufdatum darf nicht vor dem Prüfungsdatum oder dem Schulungsbeginn sein'
                }
                formValues={['examinationDate', 'dateBegin']}
                onRollback={() => form.setFieldsValue({ expiryDate: null })}
            />
        </Form>
    );
};
