import { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';

import { Resource, ResourceFormValues } from 'models';
import {
    EditableTagsGroup,
    ResourceFormModal,
    ResourceTable,
} from 'modules/resources/components';
import { useAppSelector } from 'store';
import {
    useAddNewResourceMutation,
    useUpdateResourceMutation,
} from 'store/features';

export const ResourceOverviewPage = () => {
    const [resourceForm] = useForm() as [FormInstance<ResourceFormValues>];
    const [addNewResource] = useAddNewResourceMutation();
    const [updateResource] = useUpdateResourceMutation();

    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    const [showModal, setShowModal] = useState(false);
    const [resourceToEdit, setResourceToEdit] = useState<Resource | undefined>(
        undefined
    );

    const closeModal = () => {
        setShowModal(false);
        setResourceToEdit(undefined);
    };

    const submitResource = (data: ResourceFormValues) => {
        const resource = {
            definition: data.definition,
            source: data.source,
            description: data.description,
            categoryId: data.categoryId,
            tags: data.tags,
            validity: data.validity,
        };
        if (resourceToEdit === undefined) {
            addNewResource(resource).catch((error) => console.error(error));
            setShowModal(false);
            resourceForm.resetFields();
        } else {
            updateResource({ ...resource, id: resourceToEdit.id }).catch(
                (error) => console.error(error)
            );
            setShowModal(false);
            resourceForm.resetFields();
        }
    };

    const editResource = (selectedResource: Resource) => {
        setResourceToEdit(selectedResource);
        setShowModal(true);
    };

    const showTagsIfAdmin = () => {
        if (showAdminView) {
            return (
                <div
                    style={{
                        width: '90%',
                        margin: 'auto',
                        marginBottom: '20px',
                    }}
                >
                    <h2 className="align-center">Alle Tags</h2>
                    <EditableTagsGroup />
                </div>
            );
        }
    };

    return (
        <>
            <div className="flex content-heading">
                <h1>Alle Inhalte</h1>
                <Button type="primary" onClick={() => setShowModal(true)}>
                    <PlusOutlined style={{ marginRight: 10 }} />
                    Neuer Inhalt
                </Button>
            </div>
            <div>
                <ResourceTable onOpenModal={editResource} />
            </div>
            <ResourceFormModal
                closeModal={closeModal}
                openModal={showModal}
                onSave={submitResource}
                selectedResource={resourceToEdit}
                form={resourceForm}
            />
            {showTagsIfAdmin()}
        </>
    );
};
