import { useState } from 'react';

import { Button, FormInstance, Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';

import { useGetAllTagsQuery } from 'store/features';

import { tagIsValid, validateTag } from '../utils/tagValidation';

interface IAddTagFormFieldProps {
    onCreateTag: (definition: string) => void;
    form?: FormInstance;
}

export const AddTagFormField = ({
    onCreateTag,
    form,
}: IAddTagFormFieldProps) => {
    const [inputValue, setInputValue] = useState<string>('');
    const { data: tags } = useGetAllTagsQuery();

    const handleInputConfirm = () => {
        if (tagIsValid(inputValue, tags)) {
            createTag();
            form?.setFieldsValue({ newTagInput: '' });
        }
    };

    const createTag = () => {
        onCreateTag(inputValue.toUpperCase());
        setInputValue('');
    };

    return (
        <>
            <div className="flex" style={{ gap: 5 }}>
                <FormItem
                    name="newTagInput"
                    rules={[{ validator: () => validateTag(inputValue, tags) }]}
                    style={{ width: '100%' }}
                >
                    <Input
                        placeholder="Neuen Tag erstellen"
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                        onPressEnter={() => handleInputConfirm()}
                        maxLength={20}
                        onKeyDown={(e) =>
                            e.key === 'Enter' ? e.preventDefault() : ''
                        }
                        data-testid="new-tag-input"
                    />
                </FormItem>
                <Button
                    type="primary"
                    data-testid="new-tag-button"
                    onClick={handleInputConfirm}
                >
                    Tag hinzufügen
                </Button>
            </div>
        </>
    );
};
