import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import {
    categoryApi,
    employeeApi,
    expertiseApi,
    learningtrailApi,
    resourceApi,
    tagApi,
} from './features';
import expertisesSlice from './features/expertisesSlice';
import userRoleSlice from './features/userRoleSlice';

const rootReducer = combineReducers({
    userRoles: userRoleSlice,
    expertises: expertisesSlice,
    [learningtrailApi.reducerPath]: learningtrailApi.reducer,
    [resourceApi.reducerPath]: resourceApi.reducer,
    [expertiseApi.reducerPath]: expertiseApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [tagApi.reducerPath]: tagApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(
                learningtrailApi.middleware,
                resourceApi.middleware,
                expertiseApi.middleware,
                employeeApi.middleware,
                tagApi.middleware,
                categoryApi.middleware
            ),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
