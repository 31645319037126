import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';

import { ILearningtrail } from 'models';
import { useAppSelector } from 'store';
import { useDeleteLearningtrailMutation } from 'store/features/learningtrailApiSlice';

import { LearningtrailResourceIcon } from './LearningtrailResourceIcon';

interface LearningtrailCardProps {
    learningtrail: ILearningtrail;
    onShowDrawer: (resourceId: number) => void;
    onEditLearningtrail: (leariningtrailId: number) => void;
}

export const LearningtrailCard = ({
    learningtrail: learningtrail,
    onShowDrawer,
    onEditLearningtrail,
}: LearningtrailCardProps) => {
    const adminView = useAppSelector((state) => state.userRoles.showAdminView);
    const [deleteLearningtrail] = useDeleteLearningtrailMutation();

    const removeLearningtrail = (learningtrailId: number) => {
        deleteLearningtrail(learningtrailId).catch((error) =>
            console.error(error)
        );
    };

    return (
        <Card bordered={false}>
            <div
                className="flex"
                style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <h3
                        style={{
                            paddingTop: 0,
                            marginTop: 0,
                        }}
                    >
                        {learningtrail.title}
                    </h3>
                    {learningtrail.description}
                    <div className="flex" style={{ marginTop: 20 }}>
                        {learningtrail.resources.map((resource, index) => {
                            return (
                                <LearningtrailResourceIcon
                                    key={resource.id}
                                    onShowDrawer={() =>
                                        onShowDrawer(resource.id)
                                    }
                                    resource={resource}
                                    lastItem={
                                        index ===
                                        learningtrail.resources.length - 1
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
                {adminView && (
                    <>
                        <div>
                            <Button
                                style={{ marginRight: 10 }}
                                type="primary"
                                onClick={() =>
                                    onEditLearningtrail(learningtrail.id)
                                }
                            >
                                <EditOutlined /> Bearbeiten
                            </Button>
                            <Button
                                type="default"
                                onClick={() =>
                                    removeLearningtrail(learningtrail.id)
                                }
                            >
                                <DeleteOutlined /> Löschen
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Card>
    );
};
