import { useCallback, useState } from 'react';

import {
    RightOutlined,
    RollbackOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { getTime, startOfDay, startOfYear } from 'date-fns';

import DatePicker from './DatePicker';

export const DateRangeFilterDropdown = ({
    confirm,
    setSelectedKeys,
}: FilterDropdownProps) => {
    const defaultStartDate = startOfYear(new Date());
    const defaultEndDate = startOfDay(new Date());
    const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

    const { RangePicker } = DatePicker;

    const submitFilter = useCallback(() => {
        if (startDate !== null && endDate !== null) {
            setSelectedKeys([`${getTime(startDate)}-${getTime(endDate)}`]);
        }
        confirm();
    }, [startDate, endDate]);

    const resetFilter = useCallback(() => {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setSelectedKeys([]);
        confirm({ closeDropdown: false });
    }, [defaultStartDate, defaultEndDate]);

    const setDateFilter = useCallback(
        (value: [Date | null, Date | null] | null) => {
            if (value === null) {
                setStartDate(null);
                setEndDate(null);
            } else {
                setStartDate(value[0]);
                setEndDate(value[1]);
            }
        },
        [setStartDate, setEndDate]
    );

    return (
        <div style={{ padding: 8 }}>
            <Space>
                <RangePicker
                    value={[startDate, endDate]}
                    format={'DD.MM.YYYY'}
                    onCalendarChange={setDateFilter}
                    separator={
                        <>
                            <RightOutlined />
                        </>
                    }
                />
                <Button icon={<RollbackOutlined />} onClick={resetFilter} />
                <Button
                    icon={<SearchOutlined />}
                    onClick={submitFilter}
                    type="primary"
                />
            </Space>
        </div>
    );
};

export const DateRangeFilterDropdownInstance = (
    props: FilterDropdownProps
): React.JSX.Element => (
    <DateRangeFilterDropdown
        prefixCls={props.prefixCls}
        visible={props.visible}
        close={props.close}
        selectedKeys={props.selectedKeys}
        setSelectedKeys={props.setSelectedKeys}
        confirm={props.confirm}
    />
);
