import { createRef } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { InputRef, Table, Tag } from 'antd';
import Column from 'antd/lib/table/Column';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { BaseSelectRef } from 'rc-select';

import { Employee, Expertise, Resource } from 'models';
import {
    DateRangeFilterDropdownInstance,
    DisplayedDate,
    Edits,
    EmployeeName,
    ResourceInformation,
    TagFilterDropdownInstance,
    Tags,
    TextFilterDropdownInstance,
} from 'modules/_common/components';
import { filterTags } from 'modules/_common/services';
import {
    filterDateRange,
    getId,
    sortByAlphabet,
    sortByDate,
    sortByDateNullsLast,
} from 'modules/_common/utils';
import { expandable } from 'modules/expertises/components';
import { useAppSelector } from 'store';

type onFilterValue = string | number | boolean;

interface IExpertiseTableProps {
    onOpenModal: (expertiseToEdit: Expertise) => void;
}

export const ExpertiseTable = ({ onOpenModal }: IExpertiseTableProps) => {
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    const allExpertises = useAppSelector(
        (state) => state.expertises.expertises
    );
    const filteredExpertises = useAppSelector(
        (state) => state.expertises.filteredExpertises
    );
    const isLoading = useAppSelector((state) => state.expertises.isLoading);
    const inputRef = createRef<InputRef>();
    const ref = createRef<BaseSelectRef>();

    return (
        <>
            {!isLoading ? (
                <Table
                    dataSource={
                        showAdminView ? allExpertises : filteredExpertises
                    }
                    rowKey={getId}
                    expandable={expandable}
                >
                    <Column
                        title="Id"
                        dataIndex="id"
                        sorter={(a: Expertise, b: Expertise) => a.id - b.id}
                        key="id"
                    />
                    <Column
                        title="Inhaltsbezeichnung"
                        dataIndex="resource"
                        sorter={(a: Expertise, b: Expertise) =>
                            sortByAlphabet(
                                a.resource.definition,
                                b.resource.definition
                            )
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            TextFilterDropdownInstance(
                                props,
                                'Inhaltsbezeichnung',
                                inputRef
                            )
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) =>
                            expertise.resource.definition
                                .toLowerCase()
                                .includes(value.toString().toLowerCase())
                        }
                        render={(resource: Resource) => (
                            <ResourceInformation
                                content={resource.definition}
                            />
                        )}
                        key="resource"
                    />
                    <Column
                        title="Anbieter"
                        dataIndex="resource"
                        sorter={(a: Expertise, b: Expertise) =>
                            sortByAlphabet(a.resource.source, b.resource.source)
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            TextFilterDropdownInstance(
                                props,
                                'Anbieter',
                                inputRef
                            )
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) =>
                            expertise.resource.source
                                .toLowerCase()
                                .includes(value.toString().toLowerCase())
                        }
                        render={(resource: Resource) => (
                            <ResourceInformation content={resource.source} />
                        )}
                        key="provider"
                    />
                    <Column
                        title="Tags"
                        dataIndex="resource"
                        filterDropdown={(props: FilterDropdownProps) =>
                            TagFilterDropdownInstance(props, ref)
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) =>
                            filterTags(
                                value.toString(),
                                expertise.resource.tags
                            )
                        }
                        render={(resource: Resource) => (
                            <Tags tags={resource.tags} />
                        )}
                        key="tags"
                    />
                    <Column
                        title="Mitarbeiter"
                        dataIndex="employee"
                        filterDropdown={(props: FilterDropdownProps) =>
                            TextFilterDropdownInstance(
                                props,
                                'Mitarbeiter',
                                inputRef
                            )
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) =>
                            `${expertise.employee.lastName}, ${expertise.employee.firstName}`
                                .toLowerCase()
                                .includes(value.toString().toLowerCase())
                        }
                        render={(employee: Employee) => (
                            <EmployeeName employee={employee} />
                        )}
                        key="employee"
                    />
                    <Column
                        title="Schulungsbeginn"
                        dataIndex="dateBegin"
                        sorter={(a: Expertise, b: Expertise) =>
                            sortByDate(a.dateBegin, b.dateBegin)
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            DateRangeFilterDropdownInstance(props)
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) => {
                            const unix: Array<string> = value
                                .toString()
                                .split('-');

                            return filterDateRange(
                                new Date(parseInt(unix[0])),
                                new Date(parseInt(unix[1])),
                                expertise.dateBegin
                            );
                        }}
                        render={(date: string | null) => (
                            <DisplayedDate date={date} />
                        )}
                        key="dateBegin"
                    />
                    <Column
                        title="Prüfungsdatum"
                        dataIndex="examinationDate"
                        sorter={(a: Expertise, b: Expertise) =>
                            sortByDate(a.examinationDate, b.examinationDate)
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            DateRangeFilterDropdownInstance(props)
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) => {
                            const unix: Array<string> = value
                                .toString()
                                .split('-');

                            return filterDateRange(
                                new Date(parseInt(unix[0])),
                                new Date(parseInt(unix[1])),
                                expertise.examinationDate
                            );
                        }}
                        render={(date: string | null) => (
                            <DisplayedDate date={date} />
                        )}
                        key="examinationDate"
                    />
                    <Column
                        title="Ablaufdatum"
                        dataIndex="expiryDate"
                        sorter={(a: Expertise, b: Expertise) =>
                            sortByDate(a.expiryDate, b.expiryDate)
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            DateRangeFilterDropdownInstance(props)
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) => {
                            const unix: Array<string> = value
                                .toString()
                                .split('-');

                            return filterDateRange(
                                new Date(parseInt(unix[0])),
                                new Date(parseInt(unix[1])),
                                expertise.expiryDate
                            );
                        }}
                        render={(date: string | null) => (
                            <DisplayedDate date={date} />
                        )}
                        key="expiryDate"
                    />
                    <Column
                        title="Status"
                        dataIndex="status"
                        render={(
                            status: string | undefined,
                            expertise: Expertise
                        ) => (
                            <Tag className={expertise.statusCssClassName ?? ''}>
                                {status ?? 'Undefiniert'}
                            </Tag>
                        )}
                        key="status"
                        sorter={(a: Expertise, b: Expertise) =>
                            sortByDateNullsLast(a.expiryDate, b.expiryDate)
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            TextFilterDropdownInstance(
                                props,
                                'Status',
                                inputRef
                            )
                        }
                        onFilter={(
                            value: onFilterValue,
                            expertise: Expertise
                        ) =>
                            `${expertise.status ?? ''}`
                                .toLocaleLowerCase()
                                .includes(value.toString().toLowerCase())
                        }
                    />
                    <Column
                        render={(expertise: Expertise) => (
                            <Edits
                                type={'expertise'}
                                id={expertise.id}
                                linkText={'expertise'}
                                onOpenModal={() => onOpenModal(expertise)}
                            />
                        )}
                    />
                </Table>
            ) : (
                <LoadingOutlined />
            )}
        </>
    );
};
