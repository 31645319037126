import { Category, LabelAndValue } from 'models';
import { sortByAlphabet } from 'modules/_common/utils';

export const mapCategoriesToOptions = (
    categories: Category[] | undefined
): Array<{ label: string; value: number }> => {
    const response = new Array<{ label: string; value: number }>();
    if (categories !== undefined && categories !== null) {
        categories.forEach((c: Category) => {
            response.push({ label: c.title, value: c.id });
        });
    }

    response.sort((a: LabelAndValue, b: LabelAndValue) =>
        sortByAlphabet(a.label, b.label)
    );

    return response;
};
