import { Tag as RenderTag } from 'antd';

import { Tag } from 'models';
import { getTagColor } from 'modules/_common/utils';

interface TagsProps {
    tags: Array<Tag>;
}
export const Tags = ({ tags }: TagsProps) => (
    <span>
        {tags.map((tag: Tag) => {
            const color = getTagColor(tag.definition.length);
            return (
                <RenderTag color={color} key={tag.id}>
                    {tag.definition.toUpperCase()}
                </RenderTag>
            );
        })}
    </span>
);
