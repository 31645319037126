import { FormInstance } from 'antd';
import add from 'date-fns/add';

import { ExpertiseFormValues, Resource } from 'models';

export const setExpiryDate = (
    dateBegin: null | Date,
    expertiseForm: FormInstance<ExpertiseFormValues>,
    resources: Array<Resource>
) => {
    if (dateBegin !== null) {
        const formValues = expertiseForm.getFieldsValue();
        const selectedResource = resources?.find(
            (resource: Resource) =>
                resource.id == parseInt(formValues.resourceId)
        );
        let expiryDate = new Date(dateBegin);

        if (selectedResource?.validity !== 0) {
            expiryDate = add(dateBegin, {
                months: selectedResource?.validity,
            });
            expertiseForm.setFieldsValue({ expiryDate: expiryDate });
        }
    }
};
