import { useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';

import { getId } from 'modules/_common/utils';
import { employeeColumns } from 'modules/employees/data';
import { useAppSelector } from 'store';
import { useGetAllEmployeesQuery } from 'store/features';

export const EmployeesOverviewPage = () => {
    const { data: employees, isLoading } = useGetAllEmployeesQuery();
    const navigate = useNavigate();
    const isAdmin = useAppSelector((state) => state.userRoles.isAdmin);
    const adminView = useAppSelector((state) => state.userRoles.showAdminView);

    useEffect(() => {
        if (!isAdmin || !adminView) {
            navigate('/');
        }
    }, [adminView]);

    return (
        <>
            <div className="flex content-heading">
                <h1>Alle Mitarbeiter</h1>
            </div>
            {isLoading ? (
                <div className="align-center">
                    <LoadingOutlined />
                </div>
            ) : (
                <div className="padding-top-2">
                    <Table
                        columns={employeeColumns}
                        dataSource={employees}
                        rowKey={getId}
                    />
                </div>
            )}
        </>
    );
};
