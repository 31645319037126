import { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, FormInstance } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { format } from 'date-fns';

import {
    Expertise,
    ExpertiseCreateBody,
    ExpertiseFormValues,
    ExpertiseUpdateBody,
} from 'models';
import { Title } from 'modules/_common/components';
import { useAppSelector } from 'store';
import {
    useAddNewExpertiseMutation,
    useGetCurrentEmployeeQuery,
    useUpdateExpertiseMutation,
} from 'store/features';

import { ExpertiseFormModal, ExpertiseTable } from './components';

export const ExpertiseOverviewPage = () => {
    const [expertiseForm] = useForm() as [FormInstance<ExpertiseFormValues>];
    const [addNewExpertise] = useAddNewExpertiseMutation();
    const [updateExpertise] = useUpdateExpertiseMutation();
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    const { data: currentEmployee, isSuccess: employeeIsFetched } =
        useGetCurrentEmployeeQuery();

    const [showModal, setShowModal] = useState(false);
    const [expertiseToEdit, setExpertiseToEdit] = useState<
        Expertise | undefined
    >(undefined);

    const getCorrectEmployeeId = (employeeId: string) => {
        if (!showAdminView && employeeIsFetched) {
            return currentEmployee.id;
        }
        if (showAdminView) {
            return parseInt(employeeId);
        }
        return 0;
    };

    const closeModal = () => {
        setShowModal(false);
        setExpertiseToEdit(undefined);
    };

    const submitExpertise = (data: ExpertiseFormValues) => {
        const expertiseToBeCreated: ExpertiseCreateBody = {
            resourceId: parseInt(data.resourceId),
            employeeIds: data.employeeIds ?? [
                getCorrectEmployeeId(data.employeeId),
            ],
            dateBegin: format(data.dateBegin, 'yyyy-MM-dd'),
            expiryDate: data.expiryDate
                ? format(data.expiryDate, 'yyyy-MM-dd')
                : null,
            examinationDate: data.examinationDate
                ? format(data.examinationDate, 'yyyy-MM-dd')
                : null,
        };

        if (expertiseToEdit === undefined) {
            addNewExpertise(expertiseToBeCreated).catch((error) =>
                console.error(error)
            );
        } else {
            const expertiseToBeUpdated: ExpertiseUpdateBody = {
                resourceId: expertiseToBeCreated.resourceId,
                dateBegin: expertiseToBeCreated.dateBegin,
                expiryDate: expertiseToBeCreated.expiryDate,
                examinationDate: expertiseToBeCreated.examinationDate,
                id: expertiseToEdit.id,
                employeeId: expertiseToBeCreated.employeeIds[0],
            };
            updateExpertise(expertiseToBeUpdated).catch((error) =>
                console.error(error)
            );
        }
        expertiseForm.resetFields();
        closeModal();
    };

    const editExpertise = (selectedExpertise: Expertise) => {
        setExpertiseToEdit(selectedExpertise);
        setShowModal(true);
    };

    return (
        <>
            <div className="flex content-heading">
                <Title isAdmin={showAdminView} titleText={'Expertisen'} />
                <Button type="primary" onClick={() => setShowModal(true)}>
                    <PlusOutlined style={{ marginRight: 10 }} />
                    Neue Expertise
                </Button>
            </div>
            <div>
                <ExpertiseTable onOpenModal={editExpertise} />
            </div>
            <ExpertiseFormModal
                closeModal={closeModal}
                openModal={showModal}
                onSave={submitExpertise}
                selectedExpertise={expertiseToEdit}
                form={expertiseForm}
            />
        </>
    );
};
