import { useEffect } from 'react';

import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import {
    MsalAuthenticationTemplate,
    MsalProvider,
    useMsal,
} from '@azure/msal-react';
import { ConfigProvider } from 'antd';

import { useAdminCheck } from 'modules/_common/hooks';
import { ContentRouting } from 'modules/app/components';
import { Header } from 'modules/app/components/Header';
import { SideMenu } from 'modules/app/components/SideMenu';
import { useAppDispatch, useAppSelector } from 'store';
import {
    filterCertificatesByEmployee,
    filterExpertisesByEmployee,
    setAdminRole,
    setAllCertificates,
    setAllExpertises,
    setExpertisesLoading,
    setShowAdminView,
    setUserName,
    useGetAllExpertisesQuery,
} from 'store/features';

import { loginRequest } from './authConfig';
import { Classes } from './models';
import * as theme from '../src/theme.less';

const classes = theme as Classes;
document.body.className = classes.body;

type AppProps = {
    pca: IPublicClientApplication;
};

const InnerApp = () => {
    const dispatch = useAppDispatch();
    const isAdmin = useAdminCheck();
    const { instance } = useMsal();
    const name = instance?.getActiveAccount()?.name;
    const certificatesQuery = useGetAllExpertisesQuery(true);
    const expertisesQuery = useGetAllExpertisesQuery(false);
    const isAdminState = useAppSelector((state) => state.userRoles.isAdmin);

    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );

    const dispatchUserRoles = () => {
        dispatch(setAdminRole(isAdmin));
        if (name) {
            dispatch(setUserName(name));
        }
    };

    const dispatchExpertises = () => {
        dispatch(setExpertisesLoading(true));
        if (certificatesQuery.isSuccess && expertisesQuery.isSuccess) {
            dispatch(setAllExpertises(expertisesQuery.data));
            dispatch(setAllCertificates(certificatesQuery.data));
            dispatch(setExpertisesLoading(false));
        }
    };

    const dispatchFilteredExpertises = () => {
        if (!showAdminView && name) {
            dispatch(filterExpertisesByEmployee(name));
            dispatch(filterCertificatesByEmployee(name));
        }
    };

    useEffect(() => {
        dispatch(setShowAdminView(isAdminState));
    }, [isAdminState]);

    useEffect(() => {
        dispatchUserRoles();
    }, [isAdmin, name]);

    useEffect(() => {
        dispatchExpertises();
    }, [certificatesQuery, expertisesQuery]);

    useEffect(() => {
        dispatchFilteredExpertises();
    }, [certificatesQuery, expertisesQuery, showAdminView]);

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#77263C',
                        colorLink: '#77263C',
                        colorLinkHover: '#AB9B89',
                    },
                }}
            >
                <div>
                    <Header />
                    <div className="app-frame">
                        <div className="flex">
                            <SideMenu />
                        </div>
                        <ContentRouting />
                    </div>
                </div>
            </ConfigProvider>
        </>
    );
};

export function App({ pca }: AppProps) {
    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
            >
                <InnerApp />
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}
