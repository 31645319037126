import { LoadingOutlined } from '@ant-design/icons';

import { EmptyCertificateState } from 'modules/_common/components';
import { useAppSelector } from 'store';

import { CertificatesCard, WelcomeCard } from './components';

export const Dashboard = () => {
    const isLoading = useAppSelector((state) => state.expertises.isLoading);
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    const allCertificates = useAppSelector(
        (state) => state.expertises.certificates
    );
    const filteredCertificates = useAppSelector(
        (state) => state.expertises.filteredCertificates
    );

    const displayedCertificates = showAdminView
        ? allCertificates
        : filteredCertificates;

    if (isLoading) {
        return (
            <>
                <WelcomeCard />
                <div className="padding-top-2 align-center">
                    <LoadingOutlined />
                </div>
            </>
        );
    }

    return (
        <>
            <WelcomeCard />
            <div className="padding-top-2">
                {!displayedCertificates ||
                displayedCertificates?.length === 0 ? (
                    <EmptyCertificateState isAdmin={showAdminView} />
                ) : (
                    <CertificatesCard
                        expertises={
                            showAdminView
                                ? allCertificates
                                : filteredCertificates
                        }
                    />
                )}
            </div>
        </>
    );
};
