import { CrownOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from 'store';
import { setShowAdminView } from 'store/features';

export const UserRoleSelect = () => {
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    const isAdmin = useAppSelector((state) => state.userRoles.isAdmin);
    const dispatch = useAppDispatch();

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Admin Ansicht',
            icon: <CrownOutlined />,
            disabled: !isAdmin,
        },
        {
            key: '2',
            label: 'User Ansicht',
            icon: <UserOutlined />,
        },
    ];

    const handleSelect = (key: string) => {
        const adminViewSelected = key === '1';
        if (isAdmin) {
            dispatch(setShowAdminView(adminViewSelected));
        }
    };

    return (
        <Dropdown
            menu={{
                items,
                selectable: true,
                defaultSelectedKeys: showAdminView ? ['1'] : ['2'],
                selectedKeys: showAdminView ? ['1'] : ['2'],
                onSelect: (value) => handleSelect(value.key),
            }}
        >
            <Typography.Link>
                <Space>
                    {showAdminView ? <CrownOutlined /> : <UserOutlined />}
                    {showAdminView ? 'Admin Ansicht' : 'User Ansicht'}
                    <DownOutlined />
                </Space>
            </Typography.Link>
        </Dropdown>
    );
};
