import { useEffect, useState } from 'react';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { parseISO, startOfToday } from 'date-fns';

import { Expertise, ExpertiseFormValues } from 'models';
import {
    useAddNewResourceMutation,
    useGetAllResourcesQuery,
} from 'store/features';

import { CreateResourceDrawer } from './CreateResourceDrawer';
import { ExpertiseForm } from './ExpertiseForm';

export interface IExpertiseFormModalProps {
    closeModal: () => void;
    openModal: boolean;
    onSave: (values: ExpertiseFormValues) => void;
    selectedExpertise: Expertise | undefined;
    form: FormInstance;
}

export const ExpertiseFormModal = ({
    closeModal,
    openModal,
    onSave,
    selectedExpertise,
    form,
}: IExpertiseFormModalProps) => {
    const [resourceForm] = useForm();
    const [createResource, { isSuccess: resourceIsCreated }] =
        useAddNewResourceMutation();
    const [drawerVisible, setDrawerVisible] = useState(false);

    const {
        data: resources,
        isSuccess: resourcesAreFetched,
        isFetching: resourcesFetching,
    } = useGetAllResourcesQuery();

    const preselectExpertise = () => {
        selectedExpertise
            ? form.setFieldsValue({
                  resourceId: selectedExpertise.resourceId.toString(),
                  employeeId: selectedExpertise.employeeId.toString(),
                  dateBegin: parseISO(selectedExpertise.dateBegin),
                  expiryDate: selectedExpertise.expiryDate
                      ? parseISO(selectedExpertise.expiryDate)
                      : null,
                  examinationDate: selectedExpertise.examinationDate
                      ? parseISO(selectedExpertise.examinationDate)
                      : null,
              })
            : form.resetFields();
    };

    useEffect(() => {
        preselectExpertise();
    }, [selectedExpertise]);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const cancelCreateResource = () => {
        resourceForm?.resetFields();
        setDrawerVisible(false);
    };

    const preselectNewResource = () => {
        if (resourcesAreFetched) {
            form.setFieldValue('resourceId', `${resources[0].id}`);
            form.setFieldValue('dateBegin', startOfToday());
        }
    };

    useEffect(() => {
        if (resourceIsCreated) {
            preselectNewResource();
            resourceForm.resetFields();
            setDrawerVisible(false);
        }
    }, [resourcesFetching]);

    return (
        <Modal
            title={
                selectedExpertise
                    ? 'Expertise bearbeiten'
                    : 'Neue Expertise erstellen'
            }
            bodyStyle={
                drawerVisible ? { minHeight: '60vh' } : { minHeight: '30vh' }
            }
            style={{ position: 'relative' }}
            footer={[
                <Button onClick={closeModal} key="cancel">
                    Abbrechen
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    form="expertise-form"
                    htmlType="submit"
                >
                    {selectedExpertise ? 'Speichern' : 'Hinzufügen'}
                </Button>,
            ]}
            onCancel={closeModal}
            open={openModal}
            width={'55%'}
            closable={!drawerVisible}
            destroyOnClose={true}
            centered={true}
        >
            {resourcesAreFetched ? (
                <>
                    <div className="padding-2 flex justify-center padding-top-40 gap-1">
                        <ExpertiseForm
                            resources={resources}
                            onFinish={onSave}
                            form={form}
                        />

                        <Button type="primary" onClick={showDrawer}>
                            <PlusOutlined />
                            Neuer Inhalt
                        </Button>
                    </div>
                </>
            ) : (
                <LoadingOutlined />
            )}
            <CreateResourceDrawer
                onFinish={createResource}
                isVisible={drawerVisible}
                form={resourceForm}
                onCancel={cancelCreateResource}
            />
        </Modal>
    );
};
