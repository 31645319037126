import { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { format } from 'date-fns';

import {
    Expertise,
    ExpertiseCreateBody,
    ExpertiseFormValues,
    ExpertiseUpdateBody,
} from 'models';
import { EmptyCertificateState, Title } from 'modules/_common/components';
import { ExpertiseFormModal } from 'modules/expertises/components/ExpertiseFormModal';
import { useAppSelector } from 'store';
import {
    useAddNewExpertiseMutation,
    useGetCurrentEmployeeQuery,
    useUpdateExpertiseMutation,
} from 'store/features';

import { CertificationsTable } from './components';

export const CertificateOverview = () => {
    const [expertiseForm] = useForm() as [FormInstance<ExpertiseFormValues>];
    const isLoading = useAppSelector((state) => state.expertises.isLoading);
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    const allCertificates = useAppSelector(
        (state) => state.expertises.certificates
    );
    const filteredCertificates = useAppSelector(
        (state) => state.expertises.filteredCertificates
    );
    const [addNewExpertise] = useAddNewExpertiseMutation();
    const [updateExpertise] = useUpdateExpertiseMutation();
    const [showModal, setShowModal] = useState(false);
    const [certificateToEdit, setCertificateToEdit] = useState<
        Expertise | undefined
    >(undefined);

    const { data: currentEmployee, isSuccess: employeeIsFetched } =
        useGetCurrentEmployeeQuery();

    const displayedCertificates = showAdminView
        ? allCertificates
        : filteredCertificates;

    const getCorrectEmployeeId = (employeeId: string) => {
        if (!showAdminView && employeeIsFetched) {
            return currentEmployee.id;
        }
        if (showAdminView) {
            return parseInt(employeeId);
        }
        return 0;
    };

    const closeModal = () => {
        setShowModal(false);
        setCertificateToEdit(undefined);
    };

    const submitCertification = (data: ExpertiseFormValues) => {
        const expertiseToBeCreated: ExpertiseCreateBody = {
            resourceId: parseInt(data.resourceId),
            employeeIds: data.employeeIds ?? [
                getCorrectEmployeeId(data.employeeId),
            ],
            dateBegin: format(data.dateBegin, 'yyyy-MM-dd'),
            expiryDate: data.expiryDate
                ? format(data.expiryDate, 'yyyy-MM-dd')
                : null,
            examinationDate: data.examinationDate
                ? format(data.examinationDate, 'yyyy-MM-dd')
                : null,
        };

        if (certificateToEdit === undefined) {
            addNewExpertise(expertiseToBeCreated).catch((error) =>
                console.error(error)
            );
        } else {
            const expertiseToBeUpdated: ExpertiseUpdateBody = {
                resourceId: expertiseToBeCreated.resourceId,
                dateBegin: expertiseToBeCreated.dateBegin,
                expiryDate: expertiseToBeCreated.expiryDate,
                examinationDate: expertiseToBeCreated.examinationDate,
                id: certificateToEdit.id,
                employeeId: expertiseToBeCreated.employeeIds[0],
            };
            updateExpertise(expertiseToBeUpdated).catch((error) =>
                console.error(error)
            );
        }
        closeModal();
    };

    const editExpertise = (selectedExpertise: Expertise) => {
        setCertificateToEdit(selectedExpertise);
        setShowModal(true);
    };

    if (isLoading) {
        return (
            <>
                <div className="flex content-heading">
                    <Title
                        isAdmin={showAdminView}
                        titleText={'Zertifikatsübersicht'}
                    />
                </div>

                <div className="padding-top-2 align-center">
                    <LoadingOutlined />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="flex content-heading">
                <Title
                    isAdmin={showAdminView}
                    titleText={'Zertifikatsübersicht'}
                />
            </div>
            <div className="padding-top-2">
                {!displayedCertificates ||
                displayedCertificates.length === 0 ? (
                    <EmptyCertificateState isAdmin={showAdminView} />
                ) : (
                    <CertificationsTable onOpenModal={editExpertise} />
                )}
            </div>
            <ExpertiseFormModal
                closeModal={closeModal}
                openModal={showModal}
                onSave={submitCertification}
                selectedExpertise={certificateToEdit}
                form={expertiseForm}
            />
        </>
    );
};
