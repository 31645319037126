import { useState } from 'react';

import { ShrinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { linkValueSplitter } from 'modules/_common/services';

const BREAKPOINT = 200;

interface DescriptionProps {
    description: string | undefined;
}

export const Description = ({ description }: DescriptionProps) => {
    const [isFolded, setIsFolded] = useState(true);

    if (description === undefined) return null;

    if (description.length < BREAKPOINT) {
        return (
            <>
                {description.split('\n').map((value, key, array) => (
                    <span key={key.toString() + value}>
                        {linkValueSplitter(value, key)}
                        {array[key + 1] !== undefined && <br />}
                    </span>
                ))}
            </>
        );
    } else {
        if (isFolded) {
            return (
                <>
                    {description
                        .substring(0, BREAKPOINT)
                        .split('\n')
                        .map((value, key, array) => (
                            <span key={key.toString() + value}>
                                {linkValueSplitter(value, key)}
                                {array[key + 1] !== undefined && <br />}
                            </span>
                        ))}
                    <Button
                        type="text"
                        style={{ paddingLeft: 7 }}
                        onClick={() => setIsFolded(false)}
                    >
                        ...
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    {description.split('\n').map((value, key, array) => (
                        <span key={key.toString() + value}>
                            {linkValueSplitter(value, key)}
                            {array[key + 1] !== undefined && <br />}
                        </span>
                    ))}
                    <Button
                        size="small"
                        style={{ marginLeft: 7 }}
                        onClick={() => setIsFolded(true)}
                    >
                        <ShrinkOutlined />
                    </Button>
                </>
            );
        }
    }
};
