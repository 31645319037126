interface ITitleProps {
    isAdmin: boolean;
    titleText: string;
}

export const Title = ({ isAdmin, titleText }: ITitleProps) => {
    if (isAdmin) {
        if (titleText === 'Expertisen') {
            return <h1>Alle {titleText}</h1>;
        } else {
            return <h1>{titleText}</h1>;
        }
    } else {
        return <h1>Deine {titleText}</h1>;
    }
};
