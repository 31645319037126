import { Expertise } from 'models';
import { linkValueSplitter } from 'modules/_common/services';

const ExpandedRow = (record: Expertise) => (
    <span>
        {record.resource.description.split('\n').map((value, index, array) => {
            return (
                <span key={index.toString() + value}>
                    {linkValueSplitter(value, index)}
                    {array[index + 1] !== undefined && <br />}
                </span>
            );
        })}
    </span>
);

export const expandable = {
    expandedRowRender: (record: Expertise) => ExpandedRow(record),
};
