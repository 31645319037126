import { useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FormInstance } from 'antd/es/form/Form';

import { Resource, ResourceFormValues } from 'models';
import { ResourceForm } from 'modules/_common/components';
import { useGetAllCategoriesQuery, useGetAllTagsQuery } from 'store/features';

export interface IResourceFormModalProps {
    closeModal: () => void;
    openModal: boolean;
    onSave: (data: ResourceFormValues) => void;
    selectedResource: Resource | undefined;
    form: FormInstance;
}

export const ResourceFormModal = ({
    closeModal,
    openModal,
    onSave,
    selectedResource,
    form,
}: IResourceFormModalProps) => {
    const tagsQuery = useGetAllTagsQuery();
    const categoriesQuery = useGetAllCategoriesQuery();
    const preselectResource = () => {
        selectedResource
            ? form.setFieldsValue({
                  ...selectedResource,
                  tags: selectedResource.tags.map((t) => t.id.toString()),
              })
            : form.resetFields();
    };
    useEffect(() => {
        preselectResource();
    }, [selectedResource]);

    return (
        <Modal
            title={
                selectedResource
                    ? 'Inhalt bearbeiten'
                    : 'Neuen Inhalt erstellen'
            }
            bodyStyle={{ minHeight: '30vh' }}
            footer={[
                <Button onClick={closeModal} key="cancel">
                    Abbrechen
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    form="resource-form"
                    htmlType="submit"
                >
                    {selectedResource ? 'Speichern' : 'Hinzufügen'}
                </Button>,
            ]}
            onCancel={closeModal}
            open={openModal}
            width={'55%'}
            destroyOnClose={true}
            centered={true}
            data-testid="resource-form-modal"
        >
            {tagsQuery.isSuccess && categoriesQuery.isSuccess ? (
                <div className="padding-2 flex justify-center padding-top-40">
                    <ResourceForm
                        onFinish={onSave}
                        tags={tagsQuery.data}
                        isDrawer={false}
                        form={form}
                        categories={categoriesQuery.data}
                    />
                </div>
            ) : (
                <LoadingOutlined />
            )}
        </Modal>
    );
};
