import { Avatar, Card, List, Tag } from 'antd';

import { Employee, Expertise } from 'models';
import { DisplayedDate } from 'modules/_common/components';

interface CertificatesCardProps {
    expertises: Expertise[];
}

export const CertificatesCard = ({ expertises }: CertificatesCardProps) => {
    const displayEmployeeName = (employee: Employee): string => {
        const fullName = employee.firstName + ' ' + employee.lastName;

        return fullName;
    };

    const displayInitials = (employee: Employee): string => {
        const initialFirstName = employee.firstName.charAt(0);
        const initialLastName = employee.lastName.charAt(0);

        const initials = initialFirstName + initialLastName;

        return initials;
    };

    return (
        <Card
            title="Ablaufende Zertifikate"
            style={{ height: 'calc(100vh - 250px)', overflowY: 'auto' }}
            bodyStyle={{ paddingTop: 0 }}
            className="hide-scrollbar"
        >
            <List
                itemLayout="horizontal"
                dataSource={expertises}
                renderItem={(expertise) => (
                    <List.Item>
                        <List.Item.Meta
                            style={{
                                alignItems: 'center',
                            }}
                            avatar={
                                <Avatar alt="avatar">
                                    {displayInitials(expertise.employee)}
                                </Avatar>
                            }
                            title={expertise.resource.definition}
                            description={
                                <>
                                    <span>Zertifikat von </span>
                                    <span className="text-primary-color">
                                        {displayEmployeeName(
                                            expertise.employee
                                        )}
                                    </span>
                                    <span> läuft am </span>
                                    <span className="text-primary-color">
                                        <DisplayedDate
                                            date={expertise.expiryDate}
                                        />
                                    </span>
                                    <span> ab.</span>
                                </>
                            }
                        />
                        <div>
                            <Tag className={expertise.statusCssClassName ?? ''}>
                                {expertise.status ?? 'Undefiniert'}
                            </Tag>
                        </div>
                    </List.Item>
                )}
            />
        </Card>
    );
};
