import { useRef, useState } from 'react';

import {
    DeleteOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, List, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import {
    Classes,
    ILearningtrailFormData,
    ILearningtrailInitialFormData,
} from 'models';
import { filterOptions } from 'modules/_common/utils';
import { mapResourcesAndSourceToOptions } from 'modules/expertises/services';
import { useAppSelector } from 'store';
import { useGetAllResourcesQuery } from 'store/features';

import * as resourceDetail from './addLearningrail.less';

const classes = resourceDetail as Classes;
document.body.className = classes.body;

interface AddLearningtrailProps {
    onFinish: (data: ILearningtrailFormData) => void;
    initialValues?: ILearningtrailInitialFormData;
}

interface IResourceItem {
    key: string;
    value: string | null;
}

export const AddLearningtrailForm = ({
    onFinish,
    initialValues,
}: AddLearningtrailProps) => {
    const [form] = useForm();
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );
    const resourcesQuery = useGetAllResourcesQuery();
    const idCounter = useRef(initialValues ? 0 : 2);

    const initialRessources: Array<IResourceItem> = [
        {
            key: 'new-0',
            value: null,
        },
        {
            key: 'new-1',
            value: null,
        },
    ];

    const [timelineItems, setTimelineItems] = useState<IResourceItem[]>(
        initialValues
            ? initialValues.resources.map(resourceToInitialValue)
            : initialRessources
    );

    const requiredSelect = [
        {
            required: true,
            message: 'Auswahl ist erforderlich!',
        },
    ];

    function resourceToInitialValue(resource: number) {
        return {
            key: resource.toString(),
            value: resource.toString(),
        };
    }

    const addResourceOption = () => {
        const newItem: IResourceItem = {
            key: `new-${idCounter.current}`,
            value: null,
        };

        if (timelineItems.length < 5) {
            const newItems = [...timelineItems, newItem];
            setTimelineItems(newItems);
        }

        idCounter.current++;
    };

    const deleteResourceOption = (deleteIndex: number) => {
        const newItems = [...timelineItems];
        setTimelineItems(
            newItems.filter((item, index) => index != deleteIndex)
        );
    };

    const handleResourceSelect = (index: number, value: string) => {
        const newItems = timelineItems;
        newItems[index].value = value;
        setTimelineItems(newItems);
    };

    return (
        <>
            <Form
                form={form}
                id="learningtrail-form"
                onFinish={onFinish}
                validateMessages={{ required: '${label} ist erforderlich!' }}
            >
                <div className="padding-top-2">
                    <Form.Item
                        label="Titel"
                        name="title"
                        initialValue={initialValues?.title}
                        rules={[
                            { required: true },
                            {
                                max: 50,
                                message:
                                    'Titel darf nicht länger als 50 Zeichen sein!',
                            },
                        ]}
                    >
                        <Input placeholder="Titel des neuen Lernpfades" />
                    </Form.Item>
                    <Form.Item
                        label="Beschreibung"
                        name="description"
                        initialValue={initialValues?.description}
                        rules={[
                            {
                                max: 400,
                                message:
                                    'Beschreibung darf nicht länger als 400 Zeichen sein!',
                            },
                        ]}
                    >
                        <Input placeholder="Kurzbeschreibung des neuen Lernpfades" />
                    </Form.Item>
                </div>
                {!resourcesQuery.isSuccess ? (
                    <LoadingOutlined />
                ) : (
                    <div className="padding-bottom-2">
                        <div className="padding-top-2">
                            <List
                                grid={{ gutter: 10, column: 1 }}
                                dataSource={timelineItems}
                                renderItem={(item, index) => (
                                    <List.Item>
                                        <div className="resource-option">
                                            <InfoCircleOutlined className="resource-option-icon" />
                                            <Form.Item
                                                name={[
                                                    'resources',
                                                    `resource${(
                                                        Math.random() + 1
                                                    )
                                                        .toString(36)
                                                        .substring(7)}`,
                                                ]}
                                                rules={requiredSelect}
                                                initialValue={item.value}
                                            >
                                                <Select
                                                    data-testid="resource-select"
                                                    placeholder="Wähle einen Inhalt"
                                                    disabled={!showAdminView}
                                                    notFoundContent="Keine passenden Ergebnisse"
                                                    showArrow
                                                    style={{ width: '100%' }}
                                                    options={mapResourcesAndSourceToOptions(
                                                        resourcesQuery.data
                                                    )}
                                                    showSearch
                                                    onSelect={(label, value) =>
                                                        handleResourceSelect(
                                                            index,
                                                            value.value
                                                        )
                                                    }
                                                    filterOption={(
                                                        inputValue,
                                                        option
                                                    ) =>
                                                        filterOptions(
                                                            inputValue,
                                                            option
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                            <Button
                                                className="remove-resource-option"
                                                type="link"
                                                onClick={() =>
                                                    deleteResourceOption(
                                                        timelineItems.indexOf(
                                                            item
                                                        )
                                                    )
                                                }
                                                disabled={
                                                    timelineItems.length < 3
                                                }
                                            >
                                                <DeleteOutlined />
                                            </Button>
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </div>

                        {timelineItems.length < 5 && (
                            <Button type="link" onClick={addResourceOption}>
                                <PlusOutlined /> Weiterer Inhalt
                            </Button>
                        )}
                    </div>
                )}
            </Form>
        </>
    );
};
