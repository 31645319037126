import { useEffect } from 'react';

import { Form, FormInstance, Input, InputNumber, Select } from 'antd';

import { Category, ResourceFormValues, Tag } from 'models';
import { CustomFormItem, TagsSelect } from 'modules/_common/components';
import { useAddNewTagMutation, useGetAllTagsQuery } from 'store/features';

import { AddTagFormField } from './AddTagFormField';
import { CreateResourceDrawerModalButtons } from './CreateResourceDrawerModalButtons';
import { mapCategoriesToOptions } from '../services';

interface IResourceFormProps {
    onFinish?: (values: ResourceFormValues) => void | Promise<void>;
    tags: Tag[];
    categories: Category[];
    isDrawer: boolean;
    form: FormInstance;
    onCancel?: () => void;
}

export const ResourceForm = ({
    onFinish,
    tags,
    categories,
    isDrawer,
    form,
    onCancel,
}: IResourceFormProps) => {
    const [createTag, { isSuccess: createTagSuccess }] = useAddNewTagMutation();
    const tagsQuery = useGetAllTagsQuery();

    const submitTag = (definition: string) => {
        createTag({ definition: definition }).catch((error) =>
            console.error(error)
        );
    };

    const updateTagsFormField = (selectedTags: string[], newTag: string) => {
        if (selectedTags.includes(newTag)) {
            return;
        }
        const newTagsFieldValue = {
            tags: selectedTags.concat(newTag),
        };
        form?.setFieldsValue(newTagsFieldValue);
    };

    const preselectNewTag = () => {
        const newTagId = tagsQuery.data?.at(-1)?.id.toString() as string;

        if (form?.getFieldValue(['tags']) === undefined) {
            form?.setFieldsValue({ tags: [newTagId] });
        }

        const selectedTagIds = form?.getFieldValue(['tags']) as string[];
        updateTagsFormField(selectedTagIds, newTagId);
    };

    useEffect(() => {
        if (tagsQuery.isSuccess && createTagSuccess) {
            preselectNewTag();
        }
    }, [tagsQuery.data]);

    return (
        <Form
            {...{ labelCol: { span: 6 }, wrapperCol: { span: 20 } }}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={{ required: '${label} ist erforderlich!' }}
            form={form}
            id="resource-form"
            style={{ width: 500 }}
            data-testid="resource-form"
        >
            <CustomFormItem
                label={'Bezeichnung'}
                name={'definition'}
                required={true}
                maxLength={150}
            >
                <Input placeholder="Titel des neuen Inhaltes" />
            </CustomFormItem>
            <CustomFormItem
                name={'source'}
                label={'Anbieter'}
                required={true}
                maxLength={150}
            >
                <Input placeholder="Name des Anbieters" />
            </CustomFormItem>
            <CustomFormItem
                name={'description'}
                label={'Beschreibung'}
                required={true}
                maxLength={2000}
            >
                <Input.TextArea
                    showCount
                    placeholder="Beschreibung des Inhaltes"
                />
            </CustomFormItem>
            <CustomFormItem label={'Tags'}>
                <CustomFormItem name={'tags'}>
                    <TagsSelect tags={tags} data-testid="tag-dropdown" />
                </CustomFormItem>
                <CustomFormItem name={'addTag'}>
                    <AddTagFormField onCreateTag={submitTag} form={form} />
                </CustomFormItem>
            </CustomFormItem>
            <CustomFormItem
                name={'categoryId'}
                label={'Kategorie'}
                required={true}
            >
                <Select
                    options={mapCategoriesToOptions(categories)}
                    placeholder="Wähle eine Kategorie"
                ></Select>
            </CustomFormItem>
            <CustomFormItem label="Gültigkeitsdauer">
                <div className="flex align-center">
                    <CustomFormItem name="validity" noStyle>
                        <InputNumber min={0} placeholder="0" />
                    </CustomFormItem>
                    <span className="form-text"> Monate</span>
                </div>
            </CustomFormItem>
            {isDrawer && form ? (
                <div style={{ marginLeft: '35%' }}>
                    <CreateResourceDrawerModalButtons
                        form={form}
                        onCancel={onCancel}
                    />
                </div>
            ) : null}
        </Form>
    );
};
