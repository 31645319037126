import { LabelAndValue, Tag } from 'models';
import { sortByAlphabet } from 'modules/_common/utils';

export const mapTagsToOptions = (
    tags: Array<Tag> | undefined
): Array<{ label: string; value: string }> => {
    const response = new Array<{ label: string; value: string }>();
    if (tags !== undefined && tags !== null) {
        tags.forEach((t: Tag) => {
            response.push({ label: t.definition, value: t.id.toString() });
        });
    }

    response.sort((a: LabelAndValue, b: LabelAndValue) =>
        sortByAlphabet(a.label, b.label)
    );

    return response;
};
