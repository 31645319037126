import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';

import { CustomFormItem } from 'modules/_common/components';
import { filterOptions } from 'modules/_common/utils';
import { mapEmployeesToOptions } from 'modules/expertises/services';
import { useAppSelector } from 'store';
import { useGetAllEmployeesQuery } from 'store/features';

export const ExpertiseFormEmployeeSelect = () => {
    const showAdminView = useAppSelector(
        (state) => state.userRoles.showAdminView
    );

    const { data: employees, isSuccess: employeesAreFetched } =
        useGetAllEmployeesQuery();

    return employeesAreFetched ? (
        <CustomFormItem
            name={'employeeId'}
            label={'Mitarbeiter'}
            required={true}
        >
            <Select
                data-testid="employee-input"
                disabled={!showAdminView}
                placeholder="Wähle einen Mitarbeiter"
                notFoundContent="Keine passenden Ergebnisse"
                showArrow
                style={{ width: '100%' }}
                options={mapEmployeesToOptions(employees)}
                showSearch
                filterOption={(inputValue, option) =>
                    filterOptions(inputValue, option)
                }
            />
        </CustomFormItem>
    ) : (
        <span className="align-center">
            <LoadingOutlined />
        </span>
    );
};
