import { Tag } from 'models';

export const filterTags = (value: string, tags: Array<Tag>) => {
    const splitStringArr = value.split('_').filter((empty) => empty.length > 0);
    let check = true;
    splitStringArr.forEach((split: string) => {
        const b = tags.find((tag) => tag.definition === split);
        if (b === undefined) {
            check = false;
        }
    });

    return check;
};
