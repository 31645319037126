import { createRef } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { InputRef, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { BaseSelectRef } from 'rc-select';

import { Category, Resource, Tag } from 'models';
import {
    Edits,
    TagFilterDropdownInstance,
    Tags,
    TextFilterDropdownInstance,
} from 'modules/_common/components';
import { filterTags } from 'modules/_common/services';
import { getId, sortByAlphabet } from 'modules/_common/utils';
import { renderValidity } from 'modules/resources/services';
import { useGetAllResourcesQuery } from 'store/features';

import { Description } from './Description';

type onFilterValue = string | number | boolean;

type filterValues = { text: string; value: number };

const categoryFilters: filterValues[] = [
    { text: 'Kurs', value: 1 },
    {
        text: 'Zertifikat',
        value: 2,
    },
    { text: 'Literatur', value: 3 },
    { text: 'Event', value: 4 },
];

interface IResourceTableProps {
    onOpenModal: (resourceId: Resource) => void;
}

export const ResourceTable = ({ onOpenModal }: IResourceTableProps) => {
    const { data: resources, isLoading } = useGetAllResourcesQuery();
    const inputRef = createRef<InputRef>();
    const ref = createRef<BaseSelectRef>();

    return (
        <>
            {!isLoading ? (
                <Table dataSource={resources} rowKey={getId}>
                    <Column
                        title="Id"
                        dataIndex="id"
                        sorter={(a: Resource, b: Resource) => a.id - b.id}
                        key="id"
                    />
                    <Column
                        title="Bezeichnung"
                        dataIndex="definition"
                        sorter={(a: Resource, b: Resource) =>
                            sortByAlphabet(a.definition, b.definition)
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            TextFilterDropdownInstance(
                                props,
                                'Bezeichnung',
                                inputRef
                            )
                        }
                        onFilter={(
                            value: onFilterValue,
                            resource: Resource
                        ) => {
                            return resource.definition
                                .toLowerCase()
                                .includes(value.toString().toLowerCase());
                        }}
                        key="definition"
                    />
                    <Column
                        title="Anbieter"
                        dataIndex="source"
                        sorter={(a: Resource, b: Resource) =>
                            sortByAlphabet(a.source, b.source)
                        }
                        filterDropdown={(props: FilterDropdownProps) =>
                            TextFilterDropdownInstance(
                                props,
                                'Anbieter',
                                inputRef
                            )
                        }
                        onFilter={(value: onFilterValue, resource) => {
                            return resource.source
                                .toLowerCase()
                                .includes(value.toString().toLowerCase());
                        }}
                        key="provider"
                    />
                    <Column
                        title="Beschreibung"
                        dataIndex="description"
                        render={(text: string | undefined) => (
                            <Description description={text} />
                        )}
                        key="description"
                    />
                    <Column
                        title="Kategorie"
                        dataIndex="category"
                        filters={categoryFilters}
                        onFilter={(value: onFilterValue, record: Resource) =>
                            record.categoryId === value
                        }
                        render={(category: Category) => category.title}
                        key="category"
                    />
                    <Column
                        title="Tags"
                        dataIndex="tags"
                        filterDropdown={(props: FilterDropdownProps) =>
                            TagFilterDropdownInstance(props, ref)
                        }
                        onFilter={(value: onFilterValue, resource: Resource) =>
                            filterTags(value.toString(), resource.tags)
                        }
                        render={(tags: Tag[]) => <Tags tags={tags} />}
                        key="tags"
                    />
                    <Column
                        title="Gültigkeitsdauer (Monate)"
                        dataIndex="validity"
                        sorter={(a: Resource, b: Resource) =>
                            a.validity - b.validity
                        }
                        render={(validity: number) => renderValidity(validity)}
                        key="validity"
                    />
                    <Column
                        render={(resource: Resource) => (
                            <Edits
                                type={'resource'}
                                id={resource.id}
                                linkText={'inhalt'}
                                onOpenModal={() => onOpenModal(resource)}
                            />
                        )}
                    />
                </Table>
            ) : (
                <LoadingOutlined />
            )}
        </>
    );
};
