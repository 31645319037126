import { Avatar } from 'antd';

import { Classes } from 'models';
import { useAppSelector } from 'store';

import * as header from './header.less';
import { Logout } from '../Logout';
import { UserRoleSelect } from '../UserRoleSelect';

const classes = header as Classes;
document.body.className = classes.body;

export const Header = () => {
    const userName = useAppSelector((state) => state.userRoles.userName);
    const isAdmin = useAppSelector((state) => state.userRoles.isAdmin);
    const initials = userName
        ?.split(' ')
        .map((s) => s[0])
        .join('');

    return (
        <div className="header">
            <h1>Schulungstracker</h1>
            <div className="header-button">
                <Avatar style={{ marginRight: 10 }} alt="avatar">
                    {initials}
                </Avatar>
                {userName}
            </div>
            {isAdmin && (
                <div className="header-dropdown">
                    <UserRoleSelect />
                </div>
            )}
            <span className="header-logout">
                <Logout />
            </span>
        </div>
    );
};
