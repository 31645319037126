import { Button, ColProps, Form } from 'antd';
import { Link } from 'react-router-dom';

import { Classes } from 'models';

import * as customFormButtons from './customFormButtons.less';

const classes = customFormButtons as Classes;
document.body.className = classes.body;

interface ICustomFormButtons {
    wrapperCol: ColProps | undefined;
    linkTo: string;
}

export const CustomFormButtons = ({
    wrapperCol,
    linkTo,
}: ICustomFormButtons) => {
    return (
        <Form.Item wrapperCol={wrapperCol} className="form-buttons">
            <div className="flex" style={{ gap: 10 }}>
                <Button type="primary" htmlType="submit">
                    Hinzufügen
                </Button>
                <Button type="default">
                    <Link to={linkTo}>Abbrechen</Link>
                </Button>
            </div>
        </Form.Item>
    );
};
