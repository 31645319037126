import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Expertise } from 'models';

import { filterByEmployee } from '../servies';

export interface ExpertisesState {
    expertises: Expertise[];
    certificates: Expertise[];
    filteredExpertises: Expertise[];
    filteredCertificates: Expertise[];
    isLoading: boolean;
}

const initialState: ExpertisesState = {
    expertises: [],
    certificates: [],
    filteredExpertises: [],
    filteredCertificates: [],
    isLoading: false,
};

export const ExpertisesSlice = createSlice({
    name: 'expertises',
    initialState: initialState,
    reducers: {
        setAllExpertises: (state, action: PayloadAction<Expertise[]>) => {
            return { ...state, expertises: action.payload };
        },
        setAllCertificates: (state, action: PayloadAction<Expertise[]>) => {
            return { ...state, certificates: action.payload };
        },
        filterExpertisesByEmployee: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                filteredExpertises: filterByEmployee(
                    action.payload,
                    state.expertises
                ),
            };
        },
        filterCertificatesByEmployee: (
            state,
            action: PayloadAction<string>
        ) => {
            return {
                ...state,
                filteredCertificates: filterByEmployee(
                    action.payload,
                    state.certificates
                ),
            };
        },
        setExpertisesLoading: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
    },
});

export default ExpertisesSlice.reducer;
export const {
    filterCertificatesByEmployee,
    filterExpertisesByEmployee,
    setAllCertificates,
    setAllExpertises,
    setExpertisesLoading,
} = ExpertisesSlice.actions;
