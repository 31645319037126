import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { Tag, TagCreateBody } from 'models';
import { getAccessToken } from 'modules/_common/utils';

const { API_URL } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: `${String(API_URL)}/api/`,
    prepareHeaders: async (headers) => {
        const token = await getAccessToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const tagApi = createApi({
    reducerPath: 'tags',
    baseQuery: baseQuery,
    tagTypes: ['Tags'],
    endpoints: (builder) => ({
        getAllTags: builder.query<Tag[], void>({
            query: () => 'tags',
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: 'Tags', id } as const)
                          ),
                          { type: 'Tags', id: 'LIST' },
                      ]
                    : [{ type: 'Tags', id: 'LIST' }],
        }),
        addNewTag: builder.mutation({
            query: (newTag: TagCreateBody) => ({
                url: 'tags',
                method: 'POST',
                body: newTag,
            }),
            invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
        }),
        deleteTag: builder.mutation({
            query: (tagId: number) => ({
                url: `tags/${tagId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, id) => [
                { type: 'Tags', id: id },
                { type: 'Tags', id: 'LIST' },
            ],
        }),
    }),
});

export const {
    useGetAllTagsQuery,
    useAddNewTagMutation,
    useDeleteTagMutation,
} = tagApi;
