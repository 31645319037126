import { RollbackOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';

import DatePicker from 'modules/_common/components/DatePicker';

interface IExpertiseWithDateCheckFormItem {
    label: string;
    name: string;
    dependencies?: NamePath[];
    formValues: string[];
    formAlert: string;
    onDatePickerChange?: (dateBegin: Date | null) => void;
    onRollback: React.MouseEventHandler<HTMLElement> | undefined;
}

export const ExpertiseWithDateCheckFormItem = ({
    label,
    name,
    dependencies,
    formValues,
    formAlert,
    onDatePickerChange,
    onRollback,
}: IExpertiseWithDateCheckFormItem) => {
    return (
        <Form.Item label={label}>
            <Form.Item
                name={name}
                dependencies={dependencies}
                noStyle
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_: RuleObject, value: Date) {
                            if (
                                name == 'examinationDate' &&
                                value != null &&
                                value < getFieldValue(formValues[0])
                            ) {
                                return Promise.reject(new Error(formAlert));
                            }
                            if (
                                name == 'expiryDate' &&
                                value != null &&
                                value < getFieldValue(formValues[0])
                            ) {
                                return Promise.reject(new Error(formAlert));
                            }
                            if (
                                name == 'expiryDate' &&
                                value != null &&
                                value < getFieldValue(formValues[1])
                            ) {
                                return Promise.reject(new Error(formAlert));
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <DatePicker
                    data-testid="date-field"
                    allowClear={false}
                    format={'DD.MM.YYYY'}
                    onChange={onDatePickerChange}
                    placeholder="Datum wählen"
                />
            </Form.Item>
            <span className="ant-form-text">
                <Button
                    data-testid="rollback-button"
                    style={{ marginLeft: 10 }}
                    icon={<RollbackOutlined />}
                    onClick={onRollback}
                />
            </span>
        </Form.Item>
    );
};
