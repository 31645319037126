import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from 'modules/_common/utils';

import { Resource, ResourceFormValues, ResourceUpdateBody } from '../../models';

const { API_URL } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: `${String(API_URL)}/api/`,
    prepareHeaders: async (headers) => {
        const token = await getAccessToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const resourceApi = createApi({
    reducerPath: 'resources',
    baseQuery: baseQuery,
    tagTypes: ['Resources'],
    endpoints: (builder) => ({
        getAllResources: builder.query<Resource[], void>({
            query: () => 'resources',
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: 'Resources', id } as const)
                          ),
                          { type: 'Resources', id: 'LIST' },
                      ]
                    : [{ type: 'Resources', id: 'LIST' }],
        }),
        getResourceById: builder.query<Resource, number>({
            query: (resourceId: number) => `resources/${resourceId}`,
            providesTags: (_result, _error, id) => [
                { type: 'Resources', id: id },
            ],
        }),
        addNewResource: builder.mutation({
            query: (newResource: ResourceFormValues) => ({
                url: 'resources',
                method: 'POST',
                body: newResource,
            }),
            invalidatesTags: [{ type: 'Resources', id: 'LIST' }],
        }),
        updateResource: builder.mutation({
            query: (resourceToUpdate: ResourceUpdateBody) => ({
                url: `resources/${resourceToUpdate.id}`,
                method: 'PUT',
                body: resourceToUpdate,
            }),
            invalidatesTags: (_result, _error, { id }) => [
                { type: 'Resources', id: id },
                { type: 'Resources', id: 'LIST' },
            ],
        }),
        deleteResource: builder.mutation({
            query: (resourceId: number) => ({
                url: `resources/${resourceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, id) => [
                { type: 'Resources', id: id },
                { type: 'Resources', id: 'LIST' },
            ],
        }),
    }),
});

export const {
    useGetAllResourcesQuery,
    useGetResourceByIdQuery,
    useAddNewResourceMutation,
    useUpdateResourceMutation,
    useDeleteResourceMutation,
} = resourceApi;
