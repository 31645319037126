import { Employee, LabelAndValue } from 'models';
import { sortByAlphabet } from 'modules/_common/utils';

export const mapEmployeesToOptions = (
    employees: Array<Employee> | undefined
): Array<{ label: string; value: string }> => {
    const response = new Array<{ label: string; value: string }>();
    if (
        employees !== undefined &&
        employees !== null &&
        employees.length !== 0
    ) {
        employees.forEach((em: Employee) => {
            if (!em) return;
            response.push({
                label: `${em.lastName}, ${em.firstName}`,
                value: em.id.toString(),
            });
        });
    }

    response.sort((a: LabelAndValue, b: LabelAndValue) =>
        sortByAlphabet(a.label, b.label)
    );

    return response;
};
