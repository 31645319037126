import { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { SkipToken, skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Modal } from 'antd';

import { ILearningtrail, ILearningtrailFormData } from 'models';
import { useGetLearningtrailByIdQuery } from 'store/features/learningtrailApiSlice';

import { AddLearningtrailForm } from './AddLearningtrailForm';

interface IAddLearningtrailModalProps {
    closeModal: () => void;
    openModal: boolean;
    onSave: (data: ILearningtrailFormData) => void;
    editId?: number;
}

export const AddLearningtrailModal = ({
    closeModal,
    openModal,
    onSave,
    editId,
}: IAddLearningtrailModalProps) => {
    const [fetchId, setFetchId] = useState<SkipToken | number>(skipToken);

    const {
        data: initialLearningtrail,
        isLoading,
        isFetching,
    } = useGetLearningtrailByIdQuery(fetchId);

    const mapLearningtrailResponseToFormData = (
        learningtrail: ILearningtrail | undefined
    ) => {
        if (learningtrail !== undefined) {
            return {
                ...learningtrail,
                resources: learningtrail.resources.map(
                    (resource) => resource.id
                ),
            };
        }
    };

    useEffect(() => {
        if (editId) {
            setFetchId(editId);
            return;
        }
        setFetchId(skipToken);
    }, [editId]);

    return (
        <Modal
            title={editId ? 'Lernpfad bearbeiten' : 'Neuen Lernpfad erstellen'}
            footer={[
                <Button onClick={closeModal} key="cancel">
                    Abbrechen
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    form="learningtrail-form"
                    htmlType="submit"
                >
                    {editId ? 'Speichern' : 'Hinzufügen'}
                </Button>,
            ]}
            onCancel={closeModal}
            open={openModal}
            width={'40%'}
            destroyOnClose={true}
            centered={true}
        >
            {isLoading || isFetching ? (
                <LoadingOutlined />
            ) : (
                <AddLearningtrailForm
                    onFinish={(data) => onSave(data)}
                    initialValues={
                        editId
                            ? mapLearningtrailResponseToFormData(
                                  initialLearningtrail
                              )
                            : undefined
                    }
                />
            )}
        </Modal>
    );
};
