import { msalInstance } from '../../../index';

export async function getAccessToken(): Promise<string> {
    const account = msalInstance.getActiveAccount() ?? undefined;

    const response = await msalInstance.acquireTokenSilent({
        scopes: ['api://e6e627c3-902c-465e-97e4-c205e6912b68/access'],
        account,
    });

    return response.accessToken;
}
