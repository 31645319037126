interface DisplayedDateProps {
    date: string | null;
}
export const DisplayedDate = ({ date }: DisplayedDateProps) => {
    if (date === null) {
        return <span></span>;
    }

    const displayDate: Date = new Date(date);

    return (
        <span>
            {date === ''
                ? 'Kein Datum'
                : displayDate.toLocaleString('de-DE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                  })}
        </span>
    );
};
