import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, FormInstance } from 'antd';

import { ResourceFormValues } from 'models';
import { ResourceForm } from 'modules/_common/components';
import { useGetAllCategoriesQuery, useGetAllTagsQuery } from 'store/features';

interface CreateResourceDrawerProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFinish: (values: ResourceFormValues) => any;
    isVisible: boolean;
    form: FormInstance;
    onCancel: () => void;
}

export const CreateResourceDrawer = ({
    onFinish,
    isVisible,
    form,
    onCancel,
}: CreateResourceDrawerProps) => {
    const tagsQuery = useGetAllTagsQuery();
    const categoriesQuery = useGetAllCategoriesQuery();

    return (
        <Drawer
            placement="right"
            open={isVisible}
            width={'75%'}
            closable={false}
            maskClosable={false}
            maskStyle={{ borderRadius: 8 }}
            getContainer={false}
            bodyStyle={{ paddingTop: 20 }}
            data-testid="resource-drawer"
        >
            {tagsQuery.isSuccess && categoriesQuery.isSuccess ? (
                <>
                    <div className="ant-modal-title padding-bottom-4">
                        Inhalt hinzufügen
                    </div>
                    <div className="flex justify-center">
                        <ResourceForm
                            tags={tagsQuery.data}
                            onFinish={onFinish}
                            form={form}
                            isDrawer={true}
                            onCancel={onCancel}
                            categories={categoriesQuery.data}
                        />
                    </div>
                </>
            ) : (
                <div className="align-center">
                    <LoadingOutlined />
                </div>
            )}
        </Drawer>
    );
};
