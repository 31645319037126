export function getTagColor(tagLength: number) {
    switch (tagLength) {
        case 1:
        case 2:
        case 3:
            return 'purple';
        case 4:
            return 'orange';
        case 5:
        case 6:
        case 7:
            return 'blue';
        case 8:
        case 9:
            return 'cyan';
        case 10:
        case 11:
            return 'geekblue';
        case 12:
        case 13:
            return 'volcano';
        case 14:
        case 15:
            return 'red';
        case 16:
        case 17:
            return 'green';
        case 18:
        case 19:
        case 20:
            return 'gold';
        default:
            return 'lime';
    }
}
