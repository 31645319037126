import { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Empty, List, Modal } from 'antd';

import { ILearningtrail, ILearningtrailFormData, ResourceData } from 'models';
import {
    AddLearningtrailModal,
    LearningtrailCard,
    ResourceDetail,
} from 'modules/learningtrail/components';
import { useAppSelector } from 'store';
import {
    useAddNewLearningtrailMutation,
    useGetAllLearningtrailsQuery,
    useUpdateLearningtrailMutation,
} from 'store/features';

export const LearningtrailOverviewPage = () => {
    const adminView = useAppSelector((state) => state.userRoles.showAdminView);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [resourceId, setResourceId] = useState<number>(-1);
    const [editLearningtrailId, setEditlearningtrailId] = useState<number>();
    const [addNewLearningtrail] = useAddNewLearningtrailMutation();
    const [updateLearningtrail] = useUpdateLearningtrailMutation();

    const { data: learningtrails, isLoading } = useGetAllLearningtrailsQuery();

    const showDrawer = (resourceId: number) => {
        setResourceId(resourceId);
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    const showModal = () => {
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
        setEditlearningtrailId(undefined);
    };

    const errorModal = (title: string, content: string) => {
        return Modal.error({
            bodyStyle: {
                display: 'flex',
                alignItems: 'center',
                padding: 12,
            },
            centered: true,
            okType: 'danger',
            okText: 'Zurück',
            title: title,
            content: content,
        });
    };

    const showError = (errorType: string) => {
        switch (errorType) {
            case 'resourceDuplicates':
                errorModal(
                    'Mehrfach vergebener Inhalt!',
                    'Ein Inhalt wurde mehrmals ausgewählt. Jeder Inhalt darf nur einmal ausgewählt werden.'
                );
                break;
            case 'titleDuplicates':
                errorModal(
                    'Dieser Lernpfad existiert bereits!',
                    'Ein Lernpfad mit diesem Titel wurde bereits erstellt.'
                );
                break;
            default:
                console.error(
                    'Something went wrong, Learningtrail could not be created.'
                );
        }
    };

    const validateLearningTrailInput = (data: ILearningtrailFormData) => {
        const resourceIdStringsValues = Object.values(data.resources);
        const titleAlreadyExists = learningtrails?.some(
            (trail) => trail.title === data.title
        );
        const resourceDuplicates = resourceIdStringsValues.some(
            (string, index) => {
                return resourceIdStringsValues.indexOf(string) !== index;
            }
        );
        if (resourceDuplicates) {
            showError('resourceDuplicates');
            return;
        }
        if (titleAlreadyExists && !editLearningtrailId) {
            showError('titleDuplicates');
            return;
        }

        submitLearningtrail(data);
    };

    const submitLearningtrail = (data?: ILearningtrailFormData) => {
        if (data) {
            createNewLearningtrail(data);
            closeModal();
        }
    };

    const createNewLearningtrail = (data: ILearningtrailFormData) => {
        const newLearningtrail = {
            title: data.title,
            description: data.description,
            resourceIds: transformResourceIds(data.resources),
        };

        if (editLearningtrailId) {
            updateLearningtrail({
                ...newLearningtrail,
                id: editLearningtrailId,
            }).catch((error) => console.error(error));
            return;
        }

        addNewLearningtrail(newLearningtrail).catch((error) =>
            console.error(error)
        );
    };

    const transformResourceIds = (resourceIdStrings: ResourceData) => {
        const resourceIdStringsValues = Object.values(resourceIdStrings);
        const resourceIds = resourceIdStringsValues.map((resourceId) => {
            return parseInt(resourceId);
        });
        return resourceIds;
    };

    const handleEditLearningtrail = (learningtrailId: number) => {
        setEditlearningtrailId(learningtrailId);
        setOpenModal(true);
    };

    return (
        <>
            <div className="flex content-heading">
                <h1>Lernpfade</h1>
                {adminView && (
                    <Button type="primary" onClick={showModal}>
                        <PlusOutlined style={{ marginRight: 10 }} />
                        Neuer Lernpfad
                    </Button>
                )}
            </div>
            {!learningtrails ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                <List
                    grid={{ gutter: 10, column: 1 }}
                    loading={isLoading}
                    dataSource={learningtrails}
                    renderItem={(item: ILearningtrail) => (
                        <List.Item>
                            <LearningtrailCard
                                learningtrail={item}
                                onShowDrawer={showDrawer}
                                onEditLearningtrail={handleEditLearningtrail}
                            />
                        </List.Item>
                    )}
                />
            )}

            <Drawer
                title="Mehr Informationen zu diesem Inhalt"
                placement="right"
                onClose={closeDrawer}
                open={open}
                width={'55%'}
                getContainer={false}
            >
                <ResourceDetail
                    resourceId={resourceId}
                    onDrawerClose={closeDrawer}
                />
            </Drawer>
            <AddLearningtrailModal
                openModal={openModal}
                onSave={(data) => validateLearningTrailInput(data)}
                closeModal={closeModal}
                editId={editLearningtrailId}
            />
        </>
    );
};
