import { Tag } from 'antd';

import { getTagColor } from 'modules/_common/utils';

export interface ITagRenderProps {
    label: React.ReactNode;
    closable: boolean;
    onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const CustomTags = ({ label, closable, onClose }: ITagRenderProps) => {
    return (
        <Tag
            color={getTagColor(
                label == undefined || label == null
                    ? 0
                    : label.toString().length
            )}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    );
};
