import { RefObject, useCallback, useState } from 'react';

import { RollbackOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { BaseSelectRef } from 'rc-select';

import { Tag } from 'models';
import { mapTagsToOptions } from 'modules/_common/services';
import { filterOptions } from 'modules/_common/utils';

import { CustomTags, ITagRenderProps } from './CustomTags';
import { useGetAllTagsQuery } from '../../../store/features';

interface IFilterDropDownTagProps extends FilterDropdownProps {
    ref: RefObject<BaseSelectRef>;
}

const TagFilterDropdown = ({
    confirm,
    setSelectedKeys,
    ref,
}: IFilterDropDownTagProps) => {
    const [savedTags, setSavedTags] = useState<Array<string>>([]);

    const { data: tags } = useGetAllTagsQuery();

    const setChosenTags = useCallback(
        (value: string[]) => {
            setSavedTags(value);
        },
        [setSavedTags]
    );

    const submitFilter = useCallback(() => {
        let searchString = '';
        savedTags.forEach((tagId: string) => {
            const foundTag = tags?.find(
                (tag: Tag) => tag.id === parseInt(tagId)
            );
            searchString += `${String(foundTag?.definition)}_`;
        });
        updateSelectedKeys(searchString);
        confirm();
    }, [savedTags]);

    const updateSelectedKeys = (searchString: string) => {
        if (searchString.length === 0) {
            setSelectedKeys([]);
        } else {
            setSelectedKeys([searchString]);
        }
    };

    const resetFilter = useCallback(() => {
        setSelectedKeys([]);
        setSavedTags([]);
        confirm();
    }, [setSelectedKeys, setSavedTags]);

    return (
        <div style={{ padding: 8 }}>
            <Space>
                <Select
                    notFoundContent="Keine passenden Ergebnisse"
                    mode="multiple"
                    showArrow
                    ref={ref}
                    value={savedTags}
                    suffixIcon={null}
                    onChange={setChosenTags}
                    style={{ minWidth: 200 }}
                    options={mapTagsToOptions(tags)}
                    filterOption={filterOptions}
                    tagRender={({
                        label,
                        closable,
                        onClose,
                    }: ITagRenderProps) => (
                        <CustomTags
                            closable={closable}
                            onClose={onClose}
                            label={label}
                        />
                    )}
                />
                <Button icon={<RollbackOutlined />} onClick={resetFilter} />
                <Button
                    icon={<SearchOutlined />}
                    onClick={submitFilter}
                    type="primary"
                />
            </Space>
        </div>
    );
};

export const TagFilterDropdownInstance = (
    props: FilterDropdownProps,
    ref: React.RefObject<BaseSelectRef>
): React.JSX.Element => (
    <TagFilterDropdown
        ref={ref}
        prefixCls={props.prefixCls}
        setSelectedKeys={props.setSelectedKeys}
        selectedKeys={props.selectedKeys}
        confirm={props.confirm}
        close={props.close}
        visible={props.visible}
    />
);
