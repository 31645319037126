import { Configuration, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: 'e6e627c3-902c-465e-97e4-c205e6912b68',
        authority:
            'https://login.microsoftonline.com/6be90bdd-1b52-4046-b937-85d55e55843e',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
};

export const loginRequest: PopupRequest = {
    scopes: ['User.Read'],
};
