import { ColumnsType } from 'antd/lib/table';

import { Employee } from 'models/Employee';
import { sortByAlphabet } from 'modules/_common/utils';

export const employeeColumns: ColumnsType<Employee> = [
    {
        key: 'id',
        title: 'Id',
        dataIndex: 'id',
        sorter: (a: Employee, b: Employee) => a.id - b.id,
    },
    {
        key: 'lastName',
        title: 'Name',
        dataIndex: 'lastName',
        sorter: (a: Employee, b: Employee) =>
            sortByAlphabet(a.lastName, b.lastName),
    },
    {
        key: 'firstName',
        title: 'Vorname',
        dataIndex: 'firstName',
        sorter: (a: Employee, b: Employee) =>
            sortByAlphabet(a.firstName, b.firstName),
    },
];
