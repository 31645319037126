import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { Category } from 'models';
import { getAccessToken } from 'modules/_common/utils';

const { API_URL } = process.env;

const baseQuery = fetchBaseQuery({
    baseUrl: `${String(API_URL)}/api/`,
    prepareHeaders: async (headers) => {
        const token = await getAccessToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const categoryApi = createApi({
    reducerPath: 'fetchCategories',
    baseQuery: baseQuery,
    tagTypes: ['Categories'],
    endpoints: (builder) => ({
        getAllCategories: builder.query<Category[], void>({
            query: () => 'categories',
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: 'Categories', id } as const)
                          ),
                          { type: 'Categories', id: 'LIST' },
                      ]
                    : [{ type: 'Categories', id: 'LIST' }],
        }),
    }),
});

export const { useGetAllCategoriesQuery } = categoryApi;
