import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

export const useAdminCheck = () => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const { instance } = useMsal();

    const getUserRoleOnLogin = () => {
        const account = instance.getActiveAccount();
        const roles = account?.idTokenClaims?.roles;
        let hasClaim = roles && roles.includes('SchulungsTracker.Admin');
        if (!hasClaim) {
            if (process.env.NODE_ENV !== 'production') {
                hasClaim =
                    roles && roles.includes('SchulungsTracker.Testadmin');
            }
            hasClaim = hasClaim ? hasClaim : false;
        }
        setIsAdmin(hasClaim);
    };

    useEffect(() => {
        getUserRoleOnLogin();
    }, []);

    return {
        isAdmin: isAdmin,
    };
};
