import { LabelAndValue } from 'models';

export const filterOptions = (
    input: string,
    option: LabelAndValue | undefined
): boolean => {
    const label: string = option?.label?.toString().toLowerCase() || '';

    return label.includes(input.toLowerCase());
};

export const filterDateRange = (
    startDate: Date,
    endDate: Date,
    dateToCheck: string | null
) => {
    if (!dateToCheck) {
        return false;
    }

    const dateBegin = new Date(dateToCheck);

    if (dateBegin >= startDate && dateBegin <= endDate) {
        return true;
    }

    return false;
};
