import { Expertise } from 'models';

export const filterByEmployee = (
    name: string,
    expertises: Expertise[]
): Expertise[] => {
    return expertises?.filter((expertise) => {
        return (
            `${expertise?.employee.firstName} ${expertise?.employee.lastName}` ===
                name || expertise?.employee.firstName === name
        );
    });
};
