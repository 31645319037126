import { Route, Routes } from 'react-router-dom';

import { CertificateOverview } from 'modules/certifications';
import { Dashboard } from 'modules/dashboard';
import { EmployeesOverviewPage } from 'modules/employees';
import { ExpertiseOverviewPage } from 'modules/expertises';
import { LearningtrailOverviewPage } from 'modules/learningtrail';
import { ResourceOverviewPage } from 'modules/resources';
import { useAppSelector } from 'store';

export const ContentRouting = () => {
    const isAdmin = useAppSelector((state) => state.userRoles.isAdmin);

    return (
        <div className="padding-2 content hide-scrollbar">
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/zertifikate" element={<CertificateOverview />} />
                <Route path="/inhalte" element={<ResourceOverviewPage />} />

                <Route path="/expertisen" element={<ExpertiseOverviewPage />} />
                {isAdmin && (
                    <Route
                        path="/mitarbeiter"
                        element={<EmployeesOverviewPage />}
                    />
                )}
                <Route
                    path="/lernpfade"
                    element={<LearningtrailOverviewPage />}
                />
            </Routes>
        </div>
    );
};
