import { notification } from 'antd';

type action = 'Laden' | 'Bearbeiten' | 'Erstellen' | 'Löschen';

type articleNoun =
    | 'des Inhaltes'
    | 'der Inhalte'
    | 'der Expertise'
    | 'der Expertisen'
    | 'des Tags'
    | 'der Tags'
    | 'der Mitarbeiter';

export const displayError = (
    error: unknown,
    action: action,
    articleNoun: articleNoun
) => {
    let message = '';

    if (error instanceof Error) {
        message = error.message;
    }

    notification.error({
        message: 'Fehler',
        description: (
            <>
                {action} {articleNoun} fehlgeschlagen:
                <br />
                {message}
            </>
        ),
    });
};
