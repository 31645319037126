import { LabelAndValue, Resource } from 'models';
import { sortByAlphabet } from 'modules/_common/utils';

export const mapResourcesAndSourceToOptions = (
    resources: Array<Resource> | undefined
): Array<{ label: string; value: string }> => {
    const response = new Array<{ label: string; value: string }>();
    if (resources !== undefined && resources !== null) {
        resources.forEach((resource: Resource) => {
            response.push({
                label: `${resource.definition} (${resource.source})`,
                value: resource.id.toString(),
            });
        });
    }

    response.sort((a: LabelAndValue, b: LabelAndValue) =>
        sortByAlphabet(a.label, b.label)
    );

    return response;
};
